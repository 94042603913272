.secretKeyWrapper {
  margin-bottom: 20px;
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &:last-child {
    margin-bottom: 0;
  }

  svg {
    margin-right: 10px;
    cursor: pointer;

    path {
      stroke: var(--dark_color);
    }
  }

  p {
    display: flex;
  }
}
