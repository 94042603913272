@import "../../styles/vars";
@import "../../styles/mixins";


.pageNavigation {
  display: flex;
  flex-direction: column;

  list-style: none;

  li {
    margin-bottom: 10px;
    
    a {
      padding: 10px;
      display: flex;
      align-items: center;
      width: 100%;

      text-decoration: none;

      transition: all .2s linear;
      border-radius: var(--border_radius);
      color: var(--dark_color);

      svg {
        width: 15px;
        margin-right: 10px;

        path {
          stroke: var(--dark_color);
        }
      }

      &:last-of-type {
        margin-bottom: 0;
      }

      &:hover,
      &.active {

        background: var(--light_gray);
      }
    }

    @include breakpoint($mobileWidth) {
      margin: 5px;
    }
  }

  @include breakpoint($mobileWidth) {
    margin-left: -5px;
    flex-direction: row;
    flex-wrap: wrap;
  }
}