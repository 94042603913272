@import "../../../styles/vars";
@import "../../../styles/mixins";

.settingsCheckoutPage {
  .warningLink,
  .link {
    margin-bottom: var(--x-medium);
    display: flex;
    align-items: center;

    color: var(--dark_color);

    svg {
      margin-right: 10px;

      path {
        stroke: var(--dark_color);
      }

      &:last-child {
        margin-right: 0;
        margin-left: 10px;
      }
    }

    p {
      color: var(--dark_color);
    }
  }

  .link {
    margin-bottom: 0;
  }

  .brandingWrapper {
    width: 100%;
    margin-bottom: 15px;

    h2 {
      margin-bottom: 10px;

      font-size: var(--x-small);
      font-weight: 400;
      text-transform: uppercase;
    }

    .brandingInner {
      width: 100%;
      display: flex;
      align-items: center;
    }

    .inputs {
      width: 100%;
    }

    .logo {
      margin-right: var(--medium);
      min-width: 118px;
      max-width: 118px;
      height: 118px;
      display: flex;
      justify-content: center;
      align-items: center;

      font-size: var(--x-large);
      text-transform: uppercase;

      color: var(--light_color);
      border-radius: var(--border_radius);
      background: var(--dark_color);
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;

        object-fit: cover;
      }

      @include breakpoint($mobileWidth) {
        min-width: 58px;
        height: 58px;
      }
    }
  }
}