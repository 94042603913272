@import "../../../styles/vars";
@import "../../../styles/mixins";

.settingsBillingPage {

  .feePaymentsSection {
    padding: var(--box_padding);
    margin-bottom: var(--large);

    box-shadow: 2px 2px 40px var(--shadow_color);
    border-radius: var(--border_radius);

    header {
      margin-bottom: 11px;

      h2 {
        margin-bottom: 5px;
        font-weight: 500;
        font-size: var(--large);
        line-height: 1em;
      }

      p {
        font-weight: 400;
        font-size: var(--medium);
        color: var(--secondary_text_color);
      }
    }
  }
}