@import "../../styles/vars";
@import "../../styles/mixins";

.noTransactions {
  height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  text-align: center;

  svg {
    margin-bottom: 5px;

    path {
      stroke: var(--dark_color);
    }
  }

  h2 {
    margin-bottom: 10px;

    font-size: var(--large);
    font-weight: 400;
  }

  p {
    max-width: 378px;
    font-size: var(--x-small);
    font-weight: 400;

    color: var(--secondary_text_color);
  }
}

.searchWrapper {
  margin-bottom: 15px;
}

.PaymentsTable {
  .dateRangeInterface {
    top: 0;
    left: 0;
    right: auto;
  }

  .header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;

    @include breakpoint($mobileWidth) {
      flex-direction: column;
    }
  }

  .filterTabs {
    width: 100%;
    display: flex;
    margin: 10px 0;

    .filterWrapper {
      width: 140px;
      display: flex;
      align-items: center;
      padding: 10px;
      margin-right: 5px;

      font-weight: 600;
      font-size: var(--x-small);
      line-height: 1em;

      cursor: pointer;
      border-radius: var(--border_radius);


      svg {
        margin-right: 10px;

        path {
          stroke: var(--dark_color);
        }
      }

      &.active,
      &:hover {
        position: relative;

        background: var(--light_gray);
      }
    }

    .searchInput {
      margin-left: auto;
      width: 200px;

      input {
        width: 100%;
      }
    }

    @include breakpoint($tabletWidth) {
      margin-left: -5px;
      width: 100%;
      flex: 1;
      flex-wrap: wrap;

      .filterWrapper {
        margin: 3px;
        width: fit-content;
      }
    }

    @include breakpoint($mobileWidth) {
      .searchInput {
        margin: 5px 0;
        margin-left: 10px;
      }
    }
  }

  .filterButtons {
    display: flex;
    align-items: center;

    p {
      margin-right: 10px;
      color: var(--secondary_text_color);

      font-size: var(--x-small);
      line-height: 1em;
    }

    button {
      padding: 4px 6px;
      display: flex;
      align-items: center;
      margin-right: 10px;

      font-family: inherit;
      font-size: var(--x-small);
      line-height: 1.2em;

      outline: none;
      color: var(--secondary_text_color);
      border: 0.5px dashed var(--border_color);
      border-radius: var(--border_radius);
      background-color: transparent;
      cursor: pointer;

      svg {
        margin-right: 5.5px;
      }
    }
  }


  .table {
    margin-bottom: var(--medium);
    width: 100%;
    position: relative;

    overflow-x: auto;
    overflow-y: visible;

    .tableHeader {
      padding: 5px 10px;
      display: flex;
      justify-content: space-between;
      min-width: 800px;

      border-top: 1px solid var(--border_color);
      border-bottom: 1px solid var(--border_color);

      h3 {
        margin-right: 5px;
        width: 10%;

        text-transform: uppercase;
        font-weight: 400;
        font-size: var(--x-small);
        line-height: 1em;

        color: var(--secondary_text_color);

        &.large {
          width: 34%;
        }

        &.medium {
          width: 14%;
        }

        &.settings {
          width: 2%;
        }
      }
    }


  }
}

.tableBodyWrapper {
  padding: 3px 0;

  border-bottom: 1px solid var(--border_color);

  &.search {
    padding: 0;
    border-bottom: 0;
  }
}

.tableBody {
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-width: 800px;

  border-radius: var(--border_radius);
  cursor: pointer;
  color: var(--dark_color);
  text-decoration: none;

  &:hover {
    background: var(--light_gray);
  }


  .tableBodyItem {
    margin-right: 5px;
    width: 10%;

    h4 {
      font-weight: 500;
      font-size: var(--x-small);
      line-height: 1.2em;
    }

    .span {
      font-style: normal;
      font-weight: 400;

      color: var(--secondary_text_color);
    }

    p {
      font-weight: 400;
      font-size: var(--x-small);
      line-height: 1.2em;

      word-wrap: break-word;
    }

    &.large {
      width: 34%;
    }

    &.medium {
      width: 14%;
    }

    &.settings {
      width: 2%;
    }

    .actionMenuWrapper {
      position: relative;
    }
  }
}