@import "../../styles/vars";
@import "../../styles/mixins";

.balancesPage {
  .pageHeader,
  .sectionHeader {
    margin-bottom: 15px;
    padding-bottom: 15px;

    border-bottom: 1px solid var(--border_color);

    h1, h2 {
      font-size: var(--large);
    }
  }

  .pageBody {
    display: grid;
    grid-template-columns: .2fr 1fr;
    grid-column-gap: var(--x-large);

    @include breakpoint($mobileWidth) {
      grid-template-columns: 1fr;
      grid-row-gap: var(--x-large);
    }
  }

  .pageInner {
    overflow: hidden;

    .section {
      margin-bottom: var(--large);
    }

    .titleValueDisplay {
      padding-bottom: 10px;
      margin-bottom: 10px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      border-bottom: 1px solid var(--border_color);

      &:last-child {
        border-bottom: 0;
        padding-bottom: 0;
      }
    }

    .strong {
      font-weight: 600;
    }
  }
}