.paymentMethod {
  display: flex;
  align-items: center;

  font-size: var(--x-small);
  font-weight: 400;

  svg {
    margin-right: 6px;
  }
}