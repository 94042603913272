.cryptoConfig {
  padding: var(--medium);
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  border-radius: var(--border_radius);
  border: 1px solid var(--border_color);

  .head {
    display: flex;
    align-items: center;

    svg {
      margin-right: 1em;
    }

    .name {
      h3 {
        font-weight: 400;
        font-size: var(--x-small);
        line-height: 1em;
      }
      .p {
        font-size: var(--xx-small);;
      }
    }
  }


  .buttonInner {
    display: flex;
    align-items: center;
  }

  .arrow {
    margin-left: 10px;
    width: 12px;
    height: 5px;
    position: relative;

    transform: rotate(-90deg);
    transition: all .3s ease-in-out;

    &::before,
    &::after {
      content: '';
      position: absolute;
      top: 0;

      width: 60%;
      height: 1px;
      background-color: var(--dark_color);
    }

    &::before {
      left: 0;
      transform: rotate(34deg);
    }

    &::after {
      right: 0;
      transform: rotate(-34deg);
    }
  }
}