.ticketMessage {
  width: 50%;
  margin-bottom: 10px;

  .messageWrapper {
    margin-bottom: 7px;
    padding: var(--small);

    font-size: var(--medium);

    background: var(--light_gray);
    border-radius: var(--border_radius);

    p {
      margin-bottom: var(--small);
      white-space: pre-wrap;
      word-wrap: break-word;
      overflow-wrap: break-word;
    }

    time {
      font-size: var(--small);
    }
  }

  .customer {
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;

    text-transform: uppercase;

    border-radius: 50%;
    background: var(--light_gray);
  }

  &.isUsers {
    margin-left: auto;

    .user {
      display: flex;
      width: fit-content;
      margin-left: auto;

      span {
        margin-right: 10px;
        font-size: var(--x-small);
      }

      .ava {
        width: 24px;
        height: 24px;

        border-radius: 50%;
        background: var(--dark_color);

        img {
          width: 100%;
          height: 100%;

          object-fit: cover;
        }
      }
    }
  }
}