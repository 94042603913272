@import "../../styles/vars";
@import "../../styles/mixins";

.globalSearch {
  position: relative;
  width: 100%;

  background: var(--light_color);
  border-radius: var(--border_radius) var(--border_radius) 0 0;
  transition: all .2s ease-in-out;

  .front {
    display: flex;
    justify-content: space-between;
    align-items: center;

    transition: all .2s ease-in-out;

    .xSvg {
      opacity: 0;

      width: 13px;
      height: 13px;

      cursor: pointer;
      transition: all .2s ease-in-out;

      path {
        stroke: var(--dark_color);
      }
    }
  }

  .searchResult {
    padding: 13px;
    position: absolute;
    left: 0;
    width: 100%;
    z-index: 1999;
    display: none;

    opacity: 0;
    transition: all .2s ease-in-out;
    background: var(--light_color);
    border-radius: 0 0 var(--border_radius) var(--border_radius);

    .results {
      overflow: auto;
    }

    .resultSection {
      margin-bottom: var(--small);

      h2 {
        margin-bottom: 8px;
        font-size: var(--x-small);
        font-weight: 400;

        color: var(--secondary_text_color);
      }
    }

    .noResults {
      height: 180px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      svg {
        margin-bottom: 10px;
        path {
          stroke: var(--dark_color);
        }
      }

      h1 {
        margin-bottom: 10px;
        font-size: var(--large);
        font-weight: 400;
        line-height: 1em;
      }
    }
  }


  &.active {
    z-index: 2000;

    @include breakpoint($mobileWidth) {
      position: absolute;
      left: 0;
    }

    .searchResult {
      display: block;
    }
  }

  &.visible {

    .front {
      padding: 13px;


      border-bottom: 1px solid var(--border_color);
      .xSvg {
        opacity: 1;
      }
    }

    .searchResult {
      opacity: 1;
    }
  }
}

.background {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;

  opacity: 0;
  display: none;
  background-color: var(--modal_background_color);
  transition: all .2s ease-in;

  &.active {
    display: block;
  }

  &.visible {
    opacity: 1;
  }
}