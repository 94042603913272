.timeline {

  .timelineItem {
    position: relative;
    display: flex;

    .timelineDetail {
      display: flex;
      align-items: center;

      margin-left: 25px;
      margin-bottom: 25px;

      time {
        font-weight: 400;
        font-size: var(--x-small);
        line-height: 1.2em;

        color: var(--secondary_text_color);
      }

      p {
        margin-bottom: 5px;
        font-style: normal;
        font-weight: 500;
        font-size: var(--x-small);
        line-height: 1.2em;
      }

      span {
        font-weight: 400;
        font-size: var(--x-small);
        line-height: 1.2em;
        color: var(--secondary_text_color)
      }

      svg {
        margin-left: 15px;
        cursor: pointer;

        path {
          stroke: var(--dark_color);
        }
      }
    }

    .timelineProgressBarItem {
      position: absolute;
      top: 10px;
      display: flex;
      flex-direction: column;
      align-items: center;

      .timelineProgressBarItemIcon {
        svg {
          width: 10px;
          height: 10px;
        }
      }

      .timelineProgressBarItemLine {
        margin-top: 8px;
        height: 25px;
        width: 1px;

        background-color: var(--border_color);
      }
    }

    &:last-child {
      .timelineProgressBarItemLine {
        display: none;
      }
    }
  }
}