@import "../../styles/vars";
@import "../../styles/mixins";

.paymentsPage {
  margin-bottom: 50px;

  .pageHead {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: var(--large);

    .header {

      h2 {
        font-weight: 600;
        font-size: var(--large);
      }
    }

    .buttons {
      display: flex;

      button {
        transition: all .2s linear;
        &:hover {
          background: var(--light_gray);
        }
      }
    }

    @include breakpoint($mobileWidth) {
      flex-direction: column;
      text-align: center;
    }
  }

  .pageStats {
    margin-bottom: var(--large);
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: var(--large);

    @include breakpoint($mobileWidth) {
      grid-template-columns: repeat(1, 1fr);
    }
  }

  .transactions {

  }
}