@import "../../../styles/vars";
@import "../../../styles/mixins";

.settingsGeneral {

  .imageInputWrapper {
    display: flex;
    align-items: center;
  }

  .currentLogo {
    margin-right: 20px;
    position: relative;

    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 50px;
    height: 50px;

    text-transform: uppercase;
    font-size: var(--x-large);

    background: var(--dark_color);
    color: var(--light_color);
    border-radius: 50%;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;

      object-fit: cover;
      cursor: pointer;
    }

    &::before,
    &::after {
      content: '';
      position: absolute;
      height: 2px;
      width: 70%;

      background: #F2F2F2;
      opacity: 0;
      transition: all .2s linear;
      cursor: pointer;
    }

    &::before {
      transform: rotate(45deg);
    }

    &::after {
      transform: rotate(-45deg);
    }

    &:hover {
      &::before,
      &::after {
        opacity: 1;
      }
    }

    &.noBg {
      background: transparent;
    }
  }
}
