@import "../../styles/vars";
@import "../../styles/mixins";

.eventPage {
  .eventMainDetails {
    margin: 20px 0;
    padding: var(--box_padding);
    display: flex;
    justify-content: space-between;
    align-items: center;

    box-shadow: 2px 2px 40px var(--shadow_color);
    border-radius: var(--border_radius);

    .eventMainDetailsRightSide {
      display: flex;
      align-items: center;

      .titles {
        margin-right: 20px;
        overflow-wrap: break-word;
        word-wrap: break-word;

        p {
          font-weight: 400;
          font-size: var(--small);
          line-height: 1em;
        }

        h2 {
          width: 100%;
          overflow-wrap: break-word;
          word-wrap: break-word;
          font-weight: 400;
          font-size: var(--xx-large);
          line-height: 1.2em;
        }

        span {
          font-weight: 400;
          font-size: var(--small);
          line-height: 1em;

          color: var(--secondary_text_color);

          svg {
            margin-left: 5px;
          }
        }
      }

    }

    .eventMainDetailsLeftSide {
      display: flex;
      align-items: center;

      .eventIdDetail {
        margin-right: 20px;

        span {
          display: block;
          width: 100%;

          text-align: right;
          font-weight: 400;
          font-size: var(--x-small);
          line-height: 1em;

          color: var(--secondary_text_color);
        }

        p {
          font-weight: 400;
          font-size: var(--x-medium);
          line-height: 1em;
        }
      }
    }

    @include breakpoint($mobileWidth) {
      flex-direction: column;
      text-align: center;

      .eventMainDetailsRightSide {
        .titles {
          margin-bottom: 15px;
          margin-right: 0;
          width: 100%;

          h2 {
            font-size: var(--large);
          }
        }
      }

      .eventMainDetailsLeftSide {
        .eventIdDetail {
          span {
            text-align: center;
          }
        }


      }
    }
  }

  .actionBadgeWrapper {
    position: relative;
  }

  .eventDetails {
    padding: var(--box_padding);
    margin-bottom: 20px;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-row-gap: 20px;

    box-shadow: 2px 2px 40px var(--shadow_color);
    border-radius: var(--border_radius);

    @include breakpoint($mobileWidth) {
      grid-template-columns: repeat(2, 1fr);
    }

    @include breakpoint($sMobileWidth) {
      grid-template-columns: 1fr;
    }

    .eventDetailsItem {

      span, small {
        color: var(--secondary_text_color);

        font-weight: 400;
        font-size: var(--x-small);
        line-height: 1.1em;
      }

      span {
        display: block;
        margin-bottom: 5px;
      }

      p {
        font-size: var(--medium);
      }

      small {
        display: flex;

        font-size: var(--x-small);

        svg {
          margin-left: 5px;
        }
      }

    }
  }

  .eventReqRes {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 80px;
    grid-row-gap: 20px;

    h2 {
      margin-bottom: 10px;

      font-weight: 500;
      font-size: 24px;
      line-height: 30px;
    }

    .jsonWrapper {
      padding: var(--box_padding);

      box-shadow: 2px 2px 40px var(--shadow_color);
      border-radius: var(--border_radius);
    }
  }
}
