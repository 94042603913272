@import "../../styles/vars";
@import "../../styles/mixins";

.modalContentInner {
  padding: var(--box_padding);
  width: 438px;

  background: var(--light_color);
  border-radius: var(--border_radius);

  @include breakpoint($mobileWidth) {
    width: 100%;
  }

  .header {
    width: 100%;
    margin-bottom: var(--large);
    display: flex;
    justify-content: space-between;
    align-items: center;

    h1 {
      margin-bottom: 0;
      text-align: center;

      font-size: var(--medium);
      font-weight: 400;
      line-height: 1em;
    }

  }

  .body {
    text-align: center;

    h1 {
      font-size: var(--x-large);
    }
  }

  .confirmText {
    font-weight: 400;
    font-size: var(--medium);
  }

  .switchWrapper {
    width: 100%;
    margin-bottom: 1em;
    display: flex;
    align-items: center;
    justify-content: space-between;

    p {
      font-weight: 500;
    }
  }

  .fieldWrapper {
    width: 100%;
    margin-bottom: 1em;

    &:last-of-type {
      margin-bottom: 0;
    }

    h3 {
      text-align: left;
      margin-bottom: 5px;

      font-size: var(--medium);
    }

    input,
    textarea {
      width: 100%;
    }
  }

  .footer {
    margin-top: 1em;
    display: flex;
    justify-content: center;

    button:first-child {
      margin-right: 1em;
    }
  }

  &.customers {
    width: 600px;

    .header {
      margin-bottom: 30px;

      svg:first-of-type {
        width: 25px;
        height: 25px;

        margin-right: 10px;

        path {
          fill: var(--primary_color);
        }
      }

      h1 {
        flex: 0;
      }
    }

    h1 {
      margin-bottom: 10px;
      font-size: var(--xx-large);
    }

    .footer {
      display: flex;
      justify-content: center;
    }
  }
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 3000;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 0;

  background-color: var(--modal_background_color);
  opacity: 0;
  transform: translate(0);
  transition: all 0.2s linear;

  &.visible {
    opacity: 1;
    transform: translate(0) scale(1);
  }

  .modalContent {
    max-height: 100vh;

    opacity: 0;
    transition: all 0.4s ease-in-out;

    overflow: auto;

    &.visible {
      opacity: 1;
    }

    @include breakpoint($mobileWidth) {
      width: 100%;
      padding: 0 20px;
    }
  }
}


.modalInner {
  margin: 0 auto;
  padding: var(--box_padding);
  width: 438px;

  background: var(--light_color);
  border-radius: var(--border_radius);

  @include breakpoint($mobileWidth) {
    width: 100%;
  }

  .h1 {
    width: 100%;

    margin-bottom: var(--medium);
    font-weight: 400;
    font-size: var(--x-medium);
  }

  .description {
    margin-bottom: 10px;
    color: var(--secondary_text_color);
  }

  .header {
    margin-bottom: 1em;
    display: flex;
    align-items: center;

    img {
      margin-right: 10px;
    }

    h1 {
      font-weight: 700;
      margin-bottom: 0;
    }
  }

  input,
  textarea {
    width: 100%;
    margin-bottom: 1em;
  }

  .warning {
    margin-bottom: var(--medium);

    font-size: var(--x-small);
    text-transform: uppercase;
    text-decoration: underline;

    color: var(--red);
  }

  .tfaQr {
    display: block;
    margin: var(--medium) auto;
    width: 282px;
    height: 282px;
  }

  .fieldWrapper {
    width: 100%;
    margin-bottom: 1em;

    h3 {
      margin-bottom: 10px;

      font-size: var(--x-small);
      text-transform: uppercase;
      font-weight: 400;

      &.mb15 {
        margin-bottom: 15px;
      }
    }

    p {
      margin-bottom: var(--x-small);
      font-size: var(--x-small);
    }

    .smallText {
      margin-top: 10px;

      font-size: var(--xx-small);;

      color: var(--secondary_text_color);
    }

    textarea,input {
      margin-bottom: 0;
    }
  }

  .disabledImportantInput {
    position: relative;

    cursor: pointer;

    input {
      color: var(--red);
    }

    svg {
      position: absolute;
      right: 10px;
      top: 50%;
      transform: translateY(-50%);

      path {
        stroke: var(--red);
      }
    }
  }

  .confirmationsSliderMarks {
    margin-top: 5px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    font-size: var(--x-small);
  }

  .ticksFieldsWrapper {
    display: flex;
    justify-content: space-between;

    .selectWrapper {
      width: 49%;
    }

    .datePickerWrapper {
      width: 49%;
    }

    @include breakpoint($sMobileWidth) {
      flex-direction: column;

      .selectWrapper,
      .datePickerWrapper {
        width: 100%;
      }

      .datePickerWrapper {
        margin-top: 10px;
      }
    }
  }


  .switchWrapper {
    width: 100%;
    margin-bottom: var(--medium);
    display: flex;
    align-items: center;
    justify-content: space-between;

    p {
      font-size: var(--x-small);
      text-transform: uppercase;
    }

    span {
      font-size: var(--x-small);

      color: var(--secondary_text_color);
    }
  }


  .or {
    margin: 1em 0;
    text-align: center;
  }

  .button {
    button {
      width: 100%;
    }
  }

  .body {
    margin-bottom: 1em;
  }

  .checkboxWrapper {
    margin-bottom: 1em;

    p {
      font-size: var(--x-small);
    }
  }

  .confirmText {
    margin-bottom: 1em;
  }

  .buttons {
    display: flex;
    justify-content: space-between;

    button:first-of-type {
      width: 28%;
    }

    button:last-of-type {
      width: 70%;
    }
  }
}