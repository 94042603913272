.topGateways {
  .gatewayItem {
    margin-bottom: 10px;
    display: flex;
    align-items: center;

    &:last-of-type {
      margin-bottom: 0;
    }

    .gateway {
      width: 50%;
      display: flex;
      align-items: center;

      img {
        width: 16px;
        height: 16px;

        margin-right: 10px;
      }

      span {
        font-weight: 700;
      }
    }

    p {
      text-align: right;

      width: 25%;
      font-size: var(--small);

      &:last-of-type {
        margin-left: 10px;
      }
    }
  }
}

