.switch {
  input {
    position: relative;
    appearance: none;
    width: 40px!important;
    height: 20px;

    background: var(--light_gray);
    border-radius: 20px;
    cursor: pointer;
    transition: 0.4s;

    &::after {
      position: absolute;
      content: "";
      width: 15px;
      height: 15px;
      top: 50%;
      left: 3px;

      background: var(--dark_color);
      border-radius: 50%;
      transform: translateY(-50%);
      transition: 0.4s;
    }

    &:checked {
      background-color: var(--dark_color);

      &::after {
        background-color: var(--light_color);
        left: 50%;
      }
    }
  }
}