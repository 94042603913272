@import "../../../styles/vars";
@import "../../../styles/mixins";

.container {
  width: 100%;
  max-width: var(--container_width);
  padding: 0 10px 0 20px;
  margin: 0 auto;

  @include breakpoint($mobileWidth) {
    padding: 0 10px;
  }
}
