.close {
  width: 16px;
  height: 16px;
  position: relative;

  cursor: pointer;

  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 7px;
    width: 1.5px;
    height: 100%;

    background-color: var(--dark_color);
    cursor: pointer;
  }

  &::before {
    transform: rotate(45deg);
  }

  &::after {
    transform: rotate(-45deg);
  }

  &.green {
    &::before,
    &::after {
      background-color: var(--primary_color);
    }
  }

  &.red {
    &::before,
    &::after {
      background-color: var(--red);
    }
  }
}