@import "../../styles/vars";
@import "../../styles/mixins";

.cardsPayment {
  margin-bottom: var(--medium);
  padding: var(--medium);

  border: 1px solid var(--border_color);
  border-radius: var(--border_radius);

  header {
    margin-bottom: var(--medium);
    display: flex;
    align-items: center;

    svg {
      margin-right: var(--medium);

      rect {
        fill: var(--dark_color)
      }
    }

    h2 {
      font-weight: 400;
      font-size: var(--medium);
    }
  }

  .advantages {
    margin-bottom: var(--medium);
    display: flex;
    align-items: center;

    .advantage {
      margin-right: var(--medium);
      display: flex;
      align-items: center;

      .checkMark {
        width: 20px;
        height: 20px;
        margin-right: var(--medium);
        display: flex;
        justify-content: center;
        align-items: center;

        background: var(--light_gray);
        border-radius: 50%;

        svg {
          width: 8.5px;
          height: 6.5px;

          path {
            stroke: var(--dark_color);
          }
        }
      }

      p {
        color: var(--secondary_text_color);
      }
    }

    @include breakpoint($mobileWidth) {
      flex-direction: column;

      .advantage {
        margin-right: 0;
        margin-bottom: var(--medium);
      }
    }
  }

  button {
    width: 100%;

    .active {
      display: flex;
      align-items: center;
      color: var(--green);

      svg {
        width: 12px;
        height: auto;

        path {
          stroke: var(--green);
        }
      }
    }
  }


}