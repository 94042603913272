.actionMenu {
  width: 140px;
  padding: 3px;
  position: absolute;
  right: 0;
  top: 20px;
  z-index: 100;
  display: none;

  border-radius: var(--border_radius);
  box-shadow: 2px 2px 40px var(--shadow_color);
  background: var(--light_color);
  transform-origin: top right;
  transform: scale(0);
  opacity: 0;
  transition: all .3s ease-in-out;

  font-size: var(--x-small);

  .actionButton {
    margin-bottom: 5px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px 6px;

    border-radius: var(--border_radius);
    cursor: pointer;
    transition: all .2s linear;

    &:last-of-type {
      margin-bottom: 0;
    }

    svg {
      width: 13px;
      height: 13px;

      path {
        stroke: var(--dark_color);
      }
    }

    &:hover {
      background: var(--light_gray);
    }
  }

  p {
    font-size: 12px !important;
    color: var(--dark_color) !important;
  }

  &.active {
    display: block;
  }

  &.visible {
    opacity: 1;
    transform: scale(1);
  }
}