.customCheckbox {
  display: block;

  input {
    position: absolute;
    cursor: pointer;
    opacity: 0;
    z-index: -1;
  }

  label {
    height: auto;

    position: relative;
    cursor: pointer;

    span {
      font-size: var(--x-small);
    }
  }

  label:before {
    content: "";
    width: 16px;
    height: 16px;
    display: inline-block;
    position: relative;
    vertical-align: middle;
    margin-right: 13px;
    margin-top: -3px;

    cursor: pointer;
    -webkit-appearance: none;
    background-color: transparent;
    border: 1px solid var(--border_color);
    border-radius: var(--border_radius);
    transition: all 0.3s ease-out;
  }

  &::after {
    transition: all 0.3s ease-out;
  }

  input:checked {
    & + label:before {
      background-color: var(--dark_color);
    }

    & + label:after {
      content: "";
      display: block;
      position: absolute;
      top: 3px;
      left: 5.5px;
      width: 5px;
      height: 9px;
      border: solid var(--primary_color);
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);
    }
  }
}
