.settingsTaxPage {
  .comingSoon {
    height: 50vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    text-align: center;

    svg {
      margin-bottom: 10px;

      path {
        stroke: var(--dark_color);
      }
    }

    h2 {
      margin-bottom: 10px;

      font-weight: 400;
      font-size: var(--large);
    }

    p {
      max-width: 520px;

      font-size: var(--x-small);

      color: var(--secondary_text_color);
    }
  }
}