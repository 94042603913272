@import "../../styles/vars";
@import "../../styles/mixins";

.settingsPage {
  h1 {
    margin-bottom: var(--large);

    font-weight: 500;
    font-size: var(--x-large);
    line-height: 1em;
  }

  .section {
    max-width: 100%;
    margin-bottom: var(--medium);
    padding-bottom: var(--medium);

    border-bottom: 1px solid var(--border_color);

    &:last-child {
      border-bottom: 0;
    }

    .smallText {
      margin-top: 10px;

      font-size: var(--xx-small);;

      color: var(--secondary_text_color);
    }

    .sectionHeader {
      margin-bottom: var(--medium);

      display: flex;
      align-items: center;
      justify-content: space-between;

      h1 {
        margin-bottom: 5px;
        font-size: var(--medium);
      }

      p {
        color: var(--secondary_text_color);

        a {
          color: inherit;
        }
      }

      .sectionHeaderHead {
        margin-bottom: 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        h1 {
          margin-bottom: 0;
        }
      }

      button {
        min-width: fit-content;
        margin-left: var(--large);
      }
    }

    .fieldsGrid {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-column-gap: var(--x-medium);
    }

    .fieldWrapper {
      margin-bottom: var(--medium);
      width: 100%;

      &:last-of-type {
        margin-bottom: 0;
      }

      h2 {
        margin-bottom: 10px;

        text-transform: uppercase;
        font-weight: 400;
        font-size: var(--x-small);
      }

      header {
        margin-bottom: 10px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        h2 {
          margin-bottom: 0;
        }

        .p {
          margin-top: 5px;
          color: var(--secondary_text_color);
        }
      }

      input,
      textarea {
        width: 100%;
      }
    }
  }

  .settingsPageInner {
    display: grid;
    grid-template-columns: .3fr 1fr;
    grid-column-gap: var(--x-large);

    @include breakpoint($mobileWidth) {
      grid-template-columns: 1fr;
      grid-row-gap: var(--x-large);

    }
  }

  .settingsPageTabPage {
    max-width: 100%;
  }

  .settingsPageNavBar {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 20px;
    width: 100%;

    ul {
      padding: 2px;
      display: flex;
      flex-wrap: wrap;

      width: fit-content;
      list-style: none;

      background: var(--light_gray);
      border-radius: var(--border_radius);

      li {
        margin: 2px;
        a {
          display: block;
          width: 100%;
          padding: 10px 14px;

          font-weight: 400;
          font-size: var(--medium);
          line-height: 1.2em;

          border-radius: var(--border_radius);
          text-decoration: none;
          color: inherit;
        }

        &:hover a,
        &.active a {
          background-color: var(--dark_color);
          color: var(--light_color);
        }
      }

      &:last-of-type {
        margin-left: 10px;
      }
    }
  }
}