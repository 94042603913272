.slider {
  width: 100%;
  padding: 5px 0;
  display: flex;
  align-items: center;

  .sliderThumbWrapper {
    position: relative;
    display: flex;
    align-items: center;

    .sliderTooltip {
      opacity: 0;
      position: absolute;
      padding: 3px 10px;

      font-size: var(--x-small);

      top: -25px;
      left: 50%;

      background: var(--dark_color);
      color: var(--light_color);
      border-radius: 5px;
      transform: translateX(-50%);
      transition: all .2s ease-in-out;

      &::after {
        content: '';
        position: absolute;
        bottom: -10px;
        left: 50%;

        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-bottom: 5px solid transparent;
        border-top: 5px solid var(--dark_color);

        transform: translateX(-50%);
      }

      &.active {
        opacity: 1;
      }
    }

    &.active,
    &:focus {
      border-color: var(--dark_color);
    }
  }

  .sliderThumb {
    position: relative;
    z-index: 10;

    width: 13px;
    height: 13px;

    background: var(--light_color);
    border-radius: 50%;

    border: 2px solid var(--dark_color);
    cursor: pointer;
  }



  .sliderTrack {
    height: 8px;

    background: var(--light_gray);
    border-radius: var(--border_radius);

    &.sliderTrack-0 {
      background: var(--dark_color);
    }
  }
}