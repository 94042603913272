@import "../../styles/vars";
@import "../../styles/mixins";

.noCustomers {
  height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  text-align: center;

  svg {
    margin-bottom: 5px;

    path {
      stroke: var(--dark_color);
    }
  }

  h2 {
    margin-bottom: 10px;

    font-size: var(--large);
    font-weight: 400;
  }

  p {
    max-width: 378px;
    font-size: var(--x-small);
    font-weight: 400;

    color: var(--secondary_text_color);
  }
}

.CustomersTable {
  .searchInput {
    width: 100%;

    input {
      width: 100%;
    }
  }

  .tableFilters {
    margin-bottom: var(--large);
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-gap: 5px;

    button {
      display: flex;
      align-items: center;
      padding: 11px;

      font-family: inherit;
      font-size: var(--x-small);
      font-weight: 400;
      text-align: left;

      border-radius: var(--border_radius);
      background: transparent;
      transition: all .2s linear;
      cursor: pointer;
      color: var(--dark_color);
      border: none;

      svg {
        margin-right: 10px;

        path {
          stroke: var(--dark_color);
        }
      }

      &.active,
      &:hover {
        font-weight: 600;
        background: var(--light_gray);
      }
    }

    @include breakpoint($mobileWidth) {
      grid-template-columns: repeat(2, 1fr);
    }
  }



  .table {
    margin-bottom: 15px;
    width: 100%;
    position: relative;
    margin-top: 15px;

    overflow-x: auto;
    overflow-y: visible;

    .tableHeader {
      padding: 5px 10px;
      display: flex;
      justify-content: space-between;
      min-width: 900px;

      border-top: 1px solid var(--border_color);
      border-bottom: 1px solid var(--border_color);

      h3 {
        width: 10%;
        font-weight: 400;
        font-size: var(--x-small);
        line-height: 1em;

        text-transform: uppercase;

        color: var(--secondary_text_color);

        &.large {
          width: 34%;
        }

        &.medium {
          width: 16%;
        }

        &.settings {
          width: 2%;
        }
      }
    }
  }
}

.tableBodyWrapper {
  padding: 3px 0;

  border-bottom: 1px solid var(--border_color);

  .tableBody {
    padding: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-width: 900px;

    border-radius: var(--border_radius);
    cursor: pointer;
    color: var(--dark_color);
    text-decoration: none;

    &:hover {
      background: var(--light_gray);
    }

    .tableBodyItem {
      width: 10%;

      h4 {
        font-weight: 500;
        font-size: var(--x-small);
        line-height: 1em;
      }

      .span {
        display: block;
        font-style: normal;
        font-weight: 400;
        font-size: var(--x-small);

        color: var(--secondary_text_color);
      }

      p {
        font-weight: 400;
        font-size: var(--x-small);
        line-height: 1.2em;
      }

      &.large {
        width: 34%;
      }

      &.full {
        flex: 1;
      }

      &.medium {
        width: 16%;
      }

      &.settings {
        width: 2%;
      }

      .actionMenuWrapper {
        position: relative;
      }

      .customer {
        display: flex;
        align-items: center;

        .customerAva {
          display: flex;
          align-items: center;
          justify-content: center;
          min-width: 35px;
          height: 35px;
          margin-right: 10px;

          text-transform: uppercase;

          background: var(--light_gray);
          border-radius: 50%;
        }
      }
    }
  }

  &.search {
    padding: 0;

    border-bottom: 0;

    .tableBody {
      min-width: 400px;
    }
  }
}

