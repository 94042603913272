@import "../../styles/vars";
@import "../../styles/mixins";

.homeIntro {
  margin-bottom: var(--large);

  .header {
    margin-bottom: var(--small);
    padding-bottom: var(--small);
    display: flex;
    justify-content: space-between;
    align-items: center;

    border-bottom: 1px solid var(--border_color);

    h2 {
      font-weight: 600;
      font-size: var(--large);
    }

    p {
      color: var(--secondary_text_color);
    }

    .dates {
      display: flex;
    }

    @include breakpoint($mobileWidth) {
      flex-direction: column;
      text-align: center;

      div:first-of-type {
        margin-bottom: 10px;
      }
    }
  }

  .body {

    .statsGrid {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-gap: var(--xx-large);

      @include breakpoint($tabletWidth) {
        grid-template-columns: repeat(2, 1fr);
      }

      @include breakpoint($tabletWidth) {
        grid-template-columns: repeat(1, 1fr);
      }
    }

    .linkStats {
      width: 100%;

      .linkStat {
        padding-bottom: 11px;
        margin-bottom: 11px;
        display: flex;
        align-items: center;

        border-bottom: 1px solid var(--border_color);
        cursor: pointer;
        color: var(--dark_color);
        text-decoration: none;

        h3 {
          flex: 1;

          font-size: var(--x-small);
          font-weight: 400;
        }

        span {
          margin-right: var(--medium);

          color: var(--secondary_text_color);
        }

        svg {
          path {
            stroke: var(--dark_color);
          }
        }

        &:last-of-type {
          border-bottom: 0;
          margin-bottom: 0;
          padding-bottom: 0;
        }
      }
    }

    .miniStatsRow {
      .miniStat {
        width: 100%;
        display: flex;
        justify-content: space-between;

        span {
          color: var(--secondary_text_color);
        }

        h2 {
          font-size: var(--large);
        }
      }
    }

    .statsRow,
    .miniStatsRow {
      padding-bottom: var(--x-large);
      margin-bottom: var(--x-large);
      width: 100%;
      display: flex;
      justify-content: space-between;

      border-bottom: 1px solid var(--border_color);

      .vertLine {
        margin: 0 var(--x-large);
        height: 70px;
      }

      &:last-of-type {
        border-bottom: 0;
      }
    }

    .statsRow {
      height: 190px;

      .vertLine {
        height: 100%;
      }

      @include breakpoint($mobileWidth) {
        flex-wrap: wrap;
        height: auto;

        .vertLine {
          width: 100%;
          height: 1px;
          margin: var(--large) 0;
        }
      }
    }
  }

  .vertLine {
    margin: 0 20px;
    height: 100%;
    min-width: 1px;

    background: var(--border_color);
  }


}