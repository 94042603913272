@import "../../styles/vars";
@import "../../styles/mixins";


.horLine {
  margin: 0 2%;

  height: inherit;
  width: 1px;
  background-color: var(--border_color);
}

.paymentDetailPage {
  padding-bottom: 40px;
}

.paymentDetailsGrid {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 30px;

  @include breakpoint($tabletWidth) {
    grid-template-columns: 1fr;
  }
}

.mapContainer {
  margin-top: var(--large);
  width: 100%;
  height: 168px;

  overflow: hidden;
}


.paymentDetailsSection {
  margin-bottom: var(--large);

  @include breakpoint($tabletWidth) {
    width: 100%;
  }

  header {
    margin-bottom: 15px;
    padding-bottom: 15px;

    display: flex;
    justify-content: space-between;
    align-items: center;

    border-bottom: 1px solid var(--border_color);
  }

  h2 {
    font-weight: 500;
    font-size: var(--large);
    line-height: 1em;
  }

  .detailsTable {
    h3 {
      margin-bottom: 10px;
      margin-right: var(--large);
      font-size: var(--x-small);
      font-weight: 400;

      color: var(--secondary_text_color);
    }

    p {
      margin-bottom: 10px;

      word-break: break-word;

      &.link {
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }

  .detailsGrid {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }



  &_empty {
    padding: calc(var(--box_padding) * 2);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    text-align: center;

    svg {
      margin-bottom: 5px;
    }

    h2 {
      margin-bottom: 10px;
    }
  }
}