.businessSwitcher {
  margin-bottom: 10px;
  position: relative;

  .businesses {
    width: 100%;
    position: absolute;
    bottom: 60px;
    z-index: -1;
    padding: 10px;

    border: 1px solid var(--border_color);
    border-radius: var(--border_radius);
    opacity: 0;
    transition: all .2s linear;

    &.active {
      opacity: 1;
      z-index: 1;
    }
  }

  .businessWrapper {
    margin-bottom: 5px;
    padding: 9px;
    display: flex;
    align-items: center;

    border-radius: var(--border_radius);
    cursor: pointer;

    .ava {
      margin-right: 10px;
      width: 35px;
      height: 35px;
      display: flex;
      justify-content: center;
      align-items: center;

      border-radius: var(--border_radius);
      background: var(--dark_color);
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;

        object-fit: cover;
      }

      svg {
        path {
          stroke: var(--light_color);
        }
      }
    }

    h3 {
      font-weight: 400;
      font-size: var(--x-small);
    }

    p {
      color: var(--secondary_text_color);
    }

    svg {
      margin: 0 9px;

      path {
        fill: var(--dark_color);
      }
    }

    &.current {
      background: var(--light_gray);

      h3 {
        flex: 1;
      }

      .ava {
        background: var(--light_color);
      }
    }

    &:last-of-type {
      margin-bottom: 0;
    }

    &:hover {
      background: var(--light_gray);
    }
  }
}