.authReviewTerms {
  .h1 {
    margin-bottom: 5px;
    font-size: var(--xx-large);
    line-height: 1em;
  }

  .grayText {
    margin-bottom: 15px;

    color: var(--secondary_text_color);

    a {
      color: var(--dark_color);
    }
  }

  .policyWrapper {
    margin-bottom: 15px;
    display: flex;
    justify-content: flex-start;

    p {
      color: var(--dark_color);

      ul {
        padding-left: 30px;
        color: var(--secondary_text_color);
      }
    }

    svg {
      margin-right: 15px;

      path {
        stroke: var(--dark_color);
      }
    }
  }

  button {
    width: 100%;
  }
}