.warningMessage {
  padding: 20px;
  margin-bottom: 20px;

  background-color: var(--dark_color);
  border-radius: var(--border_radius);
  color: var(--light_color);

  header {
    margin-bottom: 6.6px;
    display: flex;
    align-items: center;

    h2 {
      margin-left: 10px;

      font-weight: 500;
      font-size: var(--medium);
      line-height: 1.1em;
    }

    svg {
      path {
        fill: var(--red);
      }
    }
  }

  p {
    font-size: var(--x-small);
    font-weight: 400;
    margin-bottom: 5px;
  }

  a {
    font-family: inherit;
    font-weight: 500;
    font-size: var(--medium);
    line-height: 1em;

    color: var(--red);
    text-decoration: none;
    cursor: pointer;
  }

  &.green {
    a {
      color: var(--primary_color);
    }

    header {
      svg {
        path {
          fill: var(--primary_color);
        }
      }
    }
  }

  &.red {
    a {
      color: var(--red);
    }

    header {
      svg {
        path {
          fill: var(--red);
        }
      }
    }
  }

  &.yellow {
    a {
      color: var(--yellow);
    }

    header {
      svg {
        path {
          fill: var(--yellow);
        }
      }
    }
  }
}