@import "../../styles/vars";
@import "../../styles/mixins";

.header {
  display: flex;
  align-items: center;
  height: var(--header_height);
  margin-bottom: 20px;

  border-bottom: 1px solid var(--border_color);

  @include breakpoint($mobileWidth) {
    margin-bottom: 10px;
  }

  .headerInner {
    display: flex;
    justify-content: space-between;
    align-items: center;

    font-weight: 400;
  }

  .headerLeftSide {
    width: 85%;
    display: flex;
    align-items: center;

    @include breakpoint($mobileWidth) {
      width: 100%;
    }
  }

  .headerRightSide {
    width: 110px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .iconWrapper {
      width: 35px;
      height: 35px;
      display: flex;
      justify-content: center;
      align-items: center;

      cursor: pointer;
      transition: all .2s linear;
      border-radius: var(--border_radius);

      path {
        stroke: var(--dark_color);
      }

      &:hover {
        background: var(--light_gray);
      }
    }
  }

  .profileWrapper {
    position: relative;
  }

  .burgerMenu {
    margin-left: 10px;
    display: block;
    height: 17px;

    cursor: pointer;

    svg rect {
      fill: var(--dark_color)
    }
  }
}