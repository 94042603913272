.inputWrapper {
  width: 100%;
}

.input {
  padding: 10px;

  font-weight: 400;
  font-family: inherit;
  font-size: var(--x-small);
  line-height: 1.1em;

  border: 1px solid var(--border_color);
  border-radius: var(--border_radius);
  background: transparent;
  outline: none;
  color: var(--dark_color);

  &:focus {
    background: transparent;
    border-color: var(--dark_color);
  }

  &.error {
    border-color: var(--red);
  }

  &:hover {
    border-color: var(--dark_color);
  }

  &:disabled {
    border-color: var(--border_color);
    color: var(--secondary_text_color);
  }
}

.selectWrapper {
  padding: 9px 5px;

  border-radius: var(--border_radius);
  background: transparent;
  border: 1px solid var(--border_color);
  cursor: pointer;
  transition: all .2s linear;

  .select {
    padding: 0 5px;
    width: 100%;

    font-family: inherit;
    font-weight: 400;
    font-size: var(--x-small);
    line-height: 1em;

    border: none;
    outline: none;
    background: transparent;
    color: var(--dark_color);
    cursor: pointer;
    transition: all .2s linear;
  }

  &.secondary {
    border: 0;

    &:hover {
      background: var(--light_gray);

      .select {
        background: var(--light_gray);
      }
    }
  }
}

.errorMessage {
  color: var(--red);
}


.textarea {
  padding: 12px 12px;
  min-height: 100px;

  font-family: inherit;
  font-weight: 400;
  font-size: var(--x-small);
  line-height: 1.2em;

  resize: vertical;
  border: 1px solid var(--border_color);
  border-radius: var(--border_radius);
  background: transparent;
  outline: none;
  color: var(--dark_color);

  &:focus {
    background: transparent;
    border-color: var(--dark_color);
  }

  &.error {
    border-color: var(--red);
  }

  &:hover {
    border-color: var(--dark_color);
  }
}

.priceField {
  padding: 12px;
  display: flex;

  font-family: inherit;

  background: transparent;
  border-radius: var(--border_radius);
  border: 1px solid var(--border_color);

  select, input {
    font-size: var(--x-small);
    font-family: inherit;

    background: none;
    border: none;
    outline: none;
    color: var(--dark_color);
  }

  select {
    margin-right: 10px;
  }

  &.focus {
    background: transparent;
    border-color: var(--dark_color);
  }

  &.error {
    border-color: var(--red);
  }

  &.hover {
    border-color: var(--dark_color);
  }
}

.colorInput {
  padding: 5px;
  display: flex;
  align-items: center;
  width: 100%;

  border: 1px solid var(--border_color);
  border-radius: var(--border_radius);

  .colorPickerWrapper {
    margin-right: 10px;
    width: 25px;
    height: 25px;

    position: relative;
  }

  .color {
    width: 100%;
    height: 25px;

    border-radius: var(--border_radius);
    background-color: var(--dark_color);
  }

  .colorPicker {
    position: absolute;
    top: 35px;
    left: 0;
  }

  .colorHash {
    font-weight: 400;
    font-size: var(--small);
    line-height: 1.2em;
  }
}

.fileInput {
  padding: 10px;
  width: 100%;
  display: flex;
  align-items: center;

  font-size: var(--x-small);

  border: 1px solid var(--border_color);
  background: transparent;
  border-radius: var(--border_radius);
  cursor: pointer;

  input[type=file] {
    width: 20px !important;
    opacity: 0;
    position: absolute;
    z-index: -1;
    visibility: hidden;
  }

  .icons {
    display: flex;
    margin-right: 10px;

    .removeButton {
      margin-left: 5px;
    }

    svg {
      path {
        stroke: var(--dark_color);
      }
    }
  }

  span {
    font-weight: 400;
    font-size: var(--x-small);
    line-height: 1em;
    word-break: break-word;

    color: var(--dark_color)
  }
}
