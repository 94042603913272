@import "../../styles/vars";
@import "../../styles/mixins";

.sideModal {
  position: fixed;
  right: 0;
  top: 0;
  z-index: 4000;

  padding: var(--box_padding);
  width: 100%;
  height: 100vh;
  max-width: 436px;

  box-shadow: 2px 2px 40px var(--shadow_color);
  background: var(--light_color);
  transition: all .4s ease-in-out;
  transform: translateX(100%);
  opacity: 0;

  &.visible {
    transform: translateX(0);
    opacity: 1;
  }

  @include breakpoint($sMobileWidth) {
    max-width: 300px;
  }

}


.overlay {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 4000;

  background: var(--modal_background_color);
  transition: all .4s ease-in-out;
  opacity: 0;

  &.visible {
    opacity: 1;
  }
}

.sideModalInner {
  .h1 {
    margin-bottom: var(--medium);
    font-weight: 400;
    font-size: var(--x-medium);
  }

  .description {
    margin-bottom: 10px;
    color: var(--secondary_text_color);
  }

  .header {
    margin-bottom: 1em;
    display: flex;
    align-items: center;

    img {
      margin-right: 10px;
    }

    h1 {
      font-weight: 700;
      margin-bottom: 0;
    }
  }

  input,
  textarea {
    width: 100%;
    margin-bottom: 1em;
  }

  .warning {
    margin-bottom: var(--medium);

    font-size: var(--x-small);
    text-transform: uppercase;
    text-decoration: underline;

    color: var(--red);
  }

  .tfaQr {
    display: block;
    margin: var(--medium) auto;
    width: 282px;
    height: 282px;
  }

  .fieldWrapper {
    margin-bottom: 1em;

    h3 {
      margin-bottom: 10px;

      font-size: var(--x-small);
      text-transform: uppercase;
      font-weight: 400;

      &.mb15 {
        margin-bottom: 15px;
      }
    }

    p {
      margin-bottom: var(--x-small);
      font-size: var(--x-small);
    }

    .smallText {
      margin-top: 10px;

      font-size: var(--xx-small);;

      color: var(--secondary_text_color);
    }

    textarea,input {
      margin-bottom: 0;
    }
  }

  .disabledImportantInput {
    position: relative;

    cursor: pointer;

    input {
      color: var(--red);
    }

    svg {
      position: absolute;
      right: 10px;
      top: 50%;
      transform: translateY(-50%);

      path {
        stroke: var(--red);
      }
    }
  }

  .confirmationsSliderMarks {
    margin-top: 5px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    font-size: var(--x-small);
  }

  .ticksFieldsWrapper {
    display: flex;
    justify-content: space-between;

    .selectWrapper {
      width: 49%;
    }

    .datePickerWrapper {
      width: 49%;
    }

    @include breakpoint($sMobileWidth) {
      flex-direction: column;

      .selectWrapper,
      .datePickerWrapper {
        width: 100%;
      }

      .datePickerWrapper {
        margin-top: 10px;
      }
    }
  }


  .switchWrapper {
    width: 100%;
    margin-bottom: var(--medium);
    display: flex;
    align-items: center;
    justify-content: space-between;

    p {
      font-size: var(--x-small);
      text-transform: uppercase;
    }

    span {
      font-size: var(--x-small);

      color: var(--secondary_text_color);
    }
  }


  .or {
    margin: 1em 0;
    text-align: center;
  }

  .button {
    button {
      width: 100%;
    }
  }

  .body {
    margin-bottom: 1em;
  }

  .checkboxWrapper {
    margin-bottom: 1em;

    p {
      font-size: var(--x-small);
    }
  }

  .confirmText {
    margin-bottom: 1em;
  }

  .buttons {
    display: flex;
    justify-content: space-between;

    button:first-of-type {
      width: 70%;
    }

    button:last-of-type {
      width: 28%;
    }
  }
}