@import "../../../styles/vars";
@import "../../../styles/mixins";


.settingsPaymentsPage {
  .walletsTabs {
    margin-bottom: var(--medium);
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    background: var(--light_gray);
    border-radius: var(--border_radius);

    .tab {
      padding: 5px;
      width: 50%;
      text-align: center;

      font-size: var(--x-small);

      border-radius: var(--border_radius);
      transition: all .2s linear;
      cursor: pointer;

      &.active,
      &:hover {
        background: var(--light_color);
      }
    }
  }

  .secondaryText {
    color: var(--secondary_text_color);
  }

  .payments {
    margin: var(--medium) 0;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: 10px;

    @include breakpoint($mobileWidth) {
      grid-template-columns: 1fr;
      grid-row-gap: 10px;

    }
  }
}