@import "../../../styles/vars";
@import "../../../styles/mixins";

.settingsDeveloper {
  max-width: 100%;

  .buttonInner {
    display: flex;
    align-items: center;

    svg {
      width: 14.5px;

      margin-right: 10px;
      margin-left: 0;
    }
  }

  .apiKeys {
    width: 100%;
  }
}