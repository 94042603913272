.settingsButton {
  width: 15px;
  height: 15px;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;

  cursor: pointer;

  &::before,
  &::after,
  span {
    content: '';
    width: 4px;
    height: 4px;

    border-radius: 50%;

    background-color: var(--dark_color);
  }
}