@import "../../../styles/vars";
@import "../../../styles/mixins";

.settingsLegalPage {
  .fieldBox {
    margin-bottom: var(--large);
    display: flex;
    justify-content: space-between;
    padding: var(--box_padding);

    box-shadow: 2px 2px 40px var(--shadow_color);
    border-radius: var(--border_radius);


    @include breakpoint($mobileWidth) {
      flex-direction: column;
    }

    &.switch {
      flex-direction: row;
    }


    header {
      h2 {
        margin-bottom: 2px;
        font-weight: 600;
        font-size: var(--x-medium);
        line-height: 1.2em;
      }

      p {
        font-weight: 400;
        font-size: var(--medium);
        line-height: 1.2em;

        color: var(--secondary_text_color);
      }

      @include breakpoint($mobileWidth) {
        margin-bottom: 15px;
      }
    }

    .fieldBoxBody {

      width: 40%;

      input {
        width: 100%;
      }

      button {
        margin-left: auto;
        margin-top: var(--x-medium);
      }

      .switchWrapper {
        display: flex;
        justify-content: flex-end;
        margin-left: auto;
      }

      @include breakpoint($mobileWidth) {
        width: 100%;
      }

      &.switch {
        width: 40%;
      }
    }
  }

  .buttonWrapper {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    opacity: 0;

    max-height: 0;
    visibility: hidden;
    transform: translateY(-100%);
    transition: all .2s linear;

    &.active {
      transform: translateY(0);
      margin-top: var(--large);
      max-height: 50px;
      opacity: 1;
      visibility: visible;
    }

    button {
      margin-top: 0!important;
    }
  }
}