.activeBadge {
  display: flex;
  align-items: center;

  color: var(--green);
  border-radius: var(--border_radius);
  cursor: pointer;

  svg {
    width: 8.5px;
    margin-left: 10px;

    path {
      stroke: var(--green);
    }
  }

  &.inactive {
    color: var(--red);


    svg {

      path {
        stroke: var(--red);
      }
    }
  }
}