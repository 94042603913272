.themeChanger {
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;
  transition: all .2s linear;
  border-radius: var(--border_radius);

  svg {
    path {
      stroke: var(--dark_color);
    }
  }

  &:hover {
    background: var(--light_gray);
  }
}