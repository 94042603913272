@mixin breakpoint($bp) {
  @media screen and (max-width: $bp) {
    @content;
  }
}

$tabletWidth: 1024px;
$mobileWidth: 768px;
$sMobileWidth: 480px;

*,
*:after,
*:before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

input::placeholder,
textarea::placeholder {
  color: var(--secondary_text_color);
}

body {
  font-family: -apple-system,system-ui,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif;

  background-color: var(--light_color);
  font-size: var(--x-small);
  color: var(--dark_color);
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: var(--light_gray);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--primary_color);
  border-radius: 30px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--dark_color);
}

select,
button {
  color: var(--dark_color);
  font-family: inherit;
}

select {
  background: var(--light_color);
  color: var(--dark_color);
}

.App {
  padding-left: calc(var(--sidebar_width));
  min-height: 100vh;
  display: flex;
  flex-direction: column;

  .AppContent {
    flex: 1;
  }

  &.authPage {
    padding-left: 0;
  }
}

.page {
  opacity: 0;

  transform: translateY(-100px);
  transform-style: preserve-3d;
  transition: all .5s cubic-bezier(.25,.19,.03,1.5);

  &.visible {
    opacity: 1;
    transform: translateY(0);
  }
}

:root {
  --primary_color: #A7E23D;
  --primary_color_transparent: rgba(167, 226, 61, 0.1);
  --main_color: #ffffff;
  --dark_color: #191919;
  --light_color: #ffffff;
  --light_gray: #F8F8F8;
  --dark_gray: #282828;
  --gray: #6B7384;
  --gray_transparent: rgba(107, 115, 132, 0.05);
  --light_transparent: rgba(25, 25, 25, 0.05);
  --pink: #C11B40;
  --red:  #C11B40;
  --yellow: #FFE600;
  --red_transparent: rgba(248, 57, 57, 0.05);
  --pink_transparent: #C11B400D;
  --orange: #FF7F50;
  --orange_transparent: rgba(255,127,80, 0.1);
  --green: #578643;
  --green_transparent: rgba(235, 247, 209, 1);
  --blue: #305AD6;
  --blue_transparent: #2D55B90D;
  --border_color: rgba(31, 31, 31, 0.05);
  --secondary_text_color: #B8B8B8;
  --border_radius: 5px;
  --border_radius_m: 6px;
  --shadow_color: rgba(14, 19, 6, 0.05);
  --modal_background_color: rgba(0, 0, 0, 0.55);

  --container_width: 1200px;
  --sidebar_width: 250px;
  --header_height: 65px;
  --sidebar_padding: 14px;

  --xx-small: 12px;
  --x-small: 14px;
  --small: 15px;
  --medium: 16px;
  --x-medium: 18px;
  --xx-medium: 20px;
  --large: 24px;
  --x-large: 28px;
  --xx-large: 32px;
  --xxx-large: 48px;
  --box_padding: 20px;

  @include breakpoint($tabletWidth) {
    --sidebar_width: 70px;
  }

  @include breakpoint($mobileWidth) {
    --x-large: 24px;
    --xx-large: 28px;
    --xxx-large: 36px;
    --small: 13px;
    --x-small: 12px;
    --medium: 14px;
    --x-medium: 16px;
    --large: 20px;

    --box_padding: 10px;
  }
}

[data-theme="dark"] {
  --main_color: #ffffff;
  --dark_color: #ffffff;
  --light_color: #191919;
  --light_gray: #282828;
  --dark_gray: #f8f8f8;
  --light_transparent: #FFFFFF0D;
  --shadow_color: rgba(255, 255, 255, 0.05);
  --border_color: rgba(255, 255, 255, 0.05);
  --green_transparent: rgba(87, 134, 67, 0.1);
}

[data-theme="light"] {
  --main_color: #ffffff;
  --dark_color: #191919;
  --light_color: #ffffff;
  --light_gray: #F8F8F8;
  --dark_gray: #282828;
  --shadow_color: rgba(14, 19, 6, 0.05);
  --green_transparent: rgba(235, 247, 209, 1);
}

