.profile {
  position: absolute;
  top: 30px;
  right: 0;
  width: 222px;
  z-index: 2000;
  display: none;

  opacity: 0;
  border-radius: var(--border_radius);
  background: var(--light_color);

  .business {
    padding: 10px;

    display: flex;
    align-items: center;
    position: relative;

    transition: .2s all ease-in-out;
    border-bottom: 1px solid var(--border_color);

    .profileLogo {
      margin-right: 10px;
      width: 35px;
      height: 35px;

      overflow: hidden;
      background-color: var(--light_gray);
      border-radius: var(--border_radius);

      img {
        width: 100%;
        height: 100%;

        object-fit: cover;
      }

      &.noBg {

      }
    }

    h2 {
      font-weight: 400;
      font-size: var(--x-small);
      line-height: 1em;
    }

    p {
      font-size: var(--x-small);

      color: var(--secondary_text_color);
    }

  }

  .links {
    ul {
      padding: 5px;
      list-style: none;

      li {
        a {
          padding: 6px 10px;
          margin-bottom: 5px;
          display: flex;
          align-items: center;

          font-size: var(--x-small);

          text-decoration: none;
          color: var(--dark_color);
          cursor: pointer;
          border-radius: var(--border_radius);

          svg {
            margin-right: var(--medium);

            path {
              stroke: var(--dark_color);
            }
          }
        }

        &:last-child a {
          margin-bottom: 0;
        }

        &.red {
          a {
            color: var(--red);
          }

          svg {
            path {
              stroke: var(--red);
            }
          }
        }

        &:hover {
          a {
            background: var(--light_gray);
          }
        }
      }
    }
  }

  &.active {
    display: block;
  }

  &.visible {
    opacity: 1;
  }
}




.background {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;

  opacity: 0;
  display: none;
  background-color: var(--modal_background_color);
  transition: all .2s ease-in;

  &.active {
    display: block;
  }

  &.visible {
    opacity: 1;
  }
}