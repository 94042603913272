@import "../../../styles/vars";
@import "../../../styles/mixins";

.settingsAccount {
  .helperText {
    margin-top: 10px;
    font-size: var(--xx-small);;

    color: var(--secondary_text_color);
  }
}