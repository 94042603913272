.paymentNotes {
  margin-bottom: var(--large);
  height: fit-content;

  header {
    margin-bottom: 16px;

    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  button {
    margin-bottom: var(--x-small);
    margin-left: auto;
  }

  .buttonInner {
    display: flex;
    align-items: center;

    svg {
      margin-left: 0;
      margin-right: 10px;
    }
  }
}