.statusCodeBadge {
  padding: 2px 8px;
  width: fit-content;

  color: var(--primary_color);
  background: var(--primary_color_transparent);
  border-radius: 99px;

  &.error {
    color: var(--red);
    background: var(--red_transparent);
  }
}