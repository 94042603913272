@import "../../styles/vars";
@import "../../styles/mixins";

.sidebar {
  position: fixed;
  left: 0;
  top: 0;
  width: var(--sidebar_width);
  height: 100vh;
  display: flex;
  flex-direction: column;

  border-right: 1px solid var(--border_color);

  .logoWrapper {
    padding: var(--sidebar_padding);
    display: flex;
    align-items: center;
    width: 100%;
    height: var(--header_height);

    border-bottom: 1px solid var(--border_color);

    @include breakpoint($tabletWidth) {
      justify-content: center;
    }
  }

  .navigationWrapper {
    padding: var(--sidebar_padding);
    flex: 1;
  }

  .businessSwitcherWrapper {
    padding: var(--sidebar_padding);
  }
}