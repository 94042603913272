@import "../../styles/vars";
@import "../../styles/mixins";

.statistics {
  margin-bottom: 25px;

  header {
    margin-bottom: 25px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    h2 {
      font-weight: 500;
      font-size: var(--x-large);
      line-height: 1em;
    }


  }

  .statisticsBody {
    margin-bottom: 25px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 25px;

    @include breakpoint($tabletWidth) {
      grid-template-columns: repeat(2, 1fr);
      grid-column-gap: 15px;
    }

    @include breakpoint($mobileWidth) {
      grid-template-columns: repeat(1, 1fr);
      grid-column-gap: 15px;
    }
  }

  .statWrapper {
    padding: var(--box_padding);
    box-shadow: 2px 2px 40px var(--shadow_color);
    border-radius: var(--border_radius);
  }

  .statisticsFooter {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 25px;

    @include breakpoint($mobileWidth) {
      grid-template-columns: repeat(1, 1fr);
      grid-column-gap: 15px;
    }
  }
}