@import "../../../styles/vars";
@import "../../../styles/mixins";

.settingsTeamPage {

  .fieldBox {
    margin-bottom: var(--large);

    padding: var(--box_padding);

    box-shadow: 2px 2px 40px var(--shadow_color);
    border-radius: var(--border_radius);

    &.danger {
      background: var(--red_transparent);
      color: var(--red);
    }

    header {
      display: flex;
      align-items: center;
      justify-content: space-between;

      h2 {
        margin-bottom: 2px;
        font-weight: 600;
        font-size: var(--x-medium);
        line-height: 1.2em;
      }

      p {
        font-weight: 400;
        font-size: var(--medium);
        line-height: 1.2em;

        color: var(--secondary_text_color);
      }

      .buttons {
        display: flex;
        align-items: center;

        button:first-of-type {
          margin-right: 20px;
        }
      }
    }

    .inputWrapper {
      width: 45%;

      input {
        width: 100%;
      }
    }

    .fieldBoxBody {
      display: flex;
      align-items: center;
      width: 100%;

      input {
        width: 100%;
      }

      button {
        margin-left: auto;
        margin-top: var(--x-medium);
      }
    }
  }

  .buttonWrapper {
    display: flex;
    justify-content: flex-end;
    margin-top: 15px;
    opacity: 0;
    position: absolute;
    z-index: -1;

    visibility: hidden;
    transition: all .3s ease-in;

    &.active {
      opacity: 1;
      position: relative;
      z-index: 1;
      visibility: visible;
    }
  }
}