.warningLink {
  margin-bottom: var(--x-medium);
  display: flex;
  align-items: center;

  color: var(--dark_color);

  svg {
    margin-right: 10px;

    path {
      stroke: var(--dark_color);
    }

    &:last-child {
      margin-right: 0;
      margin-left: 10px;
    }
  }

  p {
    color: var(--dark_color);
  }
}