@import "../../styles/vars";
@import "../../styles/mixins";

.nav {
  margin-left: 25px;

  ul {
    display: flex;
    list-style: none;

    li {
      cursor: pointer;

      display: flex;
      align-items: center;

      a {
        width: 100%;
        padding: 10px;
        display: flex;
        align-items: center;

        color: inherit;
        text-decoration: none;

        border-radius: var(--border_radius);
        transition: all .2s linear;

        &:hover,
        &.active {
          background-color: var(--light_gray);
        }
      }

      svg {
        margin-right: 11px;

        path {
          stroke: var(--dark_color);

          transition: fill .2s linear;
        }
      }


    }
  }

  &.vertical {
    margin-left: 0;

    ul {
      flex-direction: column;
      margin: -10px 0;

      li {
        margin: 5px 0;

      }
    }
  }

  @include breakpoint($tabletWidth) {
    ul {
      li {
        a {
          justify-content: center;
        }

        span {
          display: none;
        }

        svg {
          margin-right: 0;
        }
      }
    }
  }

}