.analyticsPage {
    .header {
      margin-bottom: var(--large);
      padding: var(--box_padding);
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      box-shadow: 2px 2px 40px var(--shadow_color);
      border-radius: var(--border_radius);

      h1 {
        margin-bottom: 5px;

        font-size: var(--large);
        font-weight: 400;
      }

      p {

      }
    }
}