@import "../../styles/vars";
@import "../../styles/mixins";

.webhookEventAttempts {
  box-shadow: 2px 2px 40px var(--shadow_color);
  border-radius: var(--border_radius);

  .header {
    display: flex;

    border-bottom: .5px solid var(--border_color);

    .head,
    .attemptDetails {
      width: 50%;
    }

    @include breakpoint($mobileWidth) {
      flex-direction: column;

      .head,
      .attemptDetails {
        width: 100%;
      }
    }

    .attemptDetails {
      padding: var(--box_padding);
      display: grid;
      grid-template-columns: repeat(3, 1fr);

      border-left: .5px solid var(--border_color);

      .detail {
        font-size: var(--x-small);

        span {
          display: block;
          margin-bottom: 4px;
          color: var(--secondary_text_color);
        }

        p {
          font-size: var(--medium);
        }
      }

      @include breakpoint($mobileWidth) {
        border-left: none;
      }
    }

    .head {
      padding: var(--box_padding) var(--box_padding) 10px var(--box_padding);
    }

    .filterTabs {
      display: flex;

      h3 {
        margin-right: 5px;
        padding: 2px 8px;
        font-weight: 400;
        font-size: var(--x-small);

        border-radius: 99px;
        transition: all .2s linear;
        cursor: pointer;

        &.active,
        &:hover {
          background: var(--dark_color);
          color: var(--light_color);
        }
      }
    }

    h1 {
      margin-bottom: 10px;
      font-weight: 400;
      font-size: var(--large);
    }
  }

  .body {
    display: flex;
    align-items: flex-start;

    .attemptItems,
    .attemptEventBody {
      width: 50%;
    }

    @include breakpoint($mobileWidth) {
      flex-direction: column;

      .attemptItems,
      .attemptEventBody {
        width: 100%;
      }

      .attemptItems {
        margin-bottom: 20px;
      }
    }

    .attemptEventBody {
      padding: var(--box_padding);

      border-left: .5px solid var(--border_color);

      h2 {
        margin-bottom: 7px;
        font-weight: 400;
        font-size: var(--x-medium);
      }

      @include breakpoint($mobileWidth) {
        border-left: none;
      }
    }

    .jsonWrapper {
      min-height: 186px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 10px;
      margin-bottom: var(--large);
      padding: 10px;

      border: .5px solid var(--border_color);
      border-radius: var(--border_radius);
    }

    .jsonViewer {
      color: var(--dark_color)!important;

      * {
        color: var(--dark_color)!important;

      }
    }

    .attemptItems {

      .attemptItemWrapper {
        padding: 8px calc(var(--box_padding) / 2);

        border-bottom: .5px solid var(--border_color);
        border-top: .5px solid var(--border_color);

        &:first-of-type {
          border-top: none;
        }

        &.active {

        }
      }

      .attemptItem {
        padding: 8px calc(var(--box_padding) / 2);
        display: flex;
        justify-content: space-between;
        align-items: center;

        cursor: pointer;
        border-radius: var(--border_radius_m);

        .head {
          display: flex;
          align-items: center;
        }

        p {
          font-weight: 400;
          font-size: var(--medium);
        }

        time {
          font-size: var(--x-small);
        }

        .attemptStatus {
          margin-right: 15px;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 22px;
          height: 22px;

          border-radius: 50%;
          background: var(--primary_color_transparent);

          &.error {
            background: var(--red_transparent);
          }
        }

        &:hover,
        &.active {
          background: var(--light_gray);
        }
      }
    }
  }
}