@import "../../styles/vars";
@import "../../styles/mixins";

.authPage {

  .authInner {
    margin-bottom: 30px;
    width: 100%;
    min-height: calc(100vh - var(--header_height) - 30px);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .authFormWrapper {
    width: 100%;
    max-width: 451px;
    display: flex;
    flex-direction: column;


    &.withSidebar {
      margin-left: var(--sidebar_width);
    }

    .authForm {
      height: 100%;
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;

      h1 {
        margin-bottom: 5px;
        font-size: var(--xx-large);
        line-height: 1em;
      }

      .description {
        font-size: var(--x-small);
        margin-bottom: 15px;

        color: var(--secondary_text_color);
      }

      .buttonInner {
        display: flex;
        align-items: center;

        svg {
          margin-right: 15px;
        }
      }

      .googleIcon {
        path {
          fill: var(--dark_color);
        }
      }

      .or {
        margin: 15px 0;
        text-align: center;
      }

      .fieldWrapper {
        margin-bottom: 15px;
        
        input,
        textarea {
          width: 100%;
        }

        textarea {
          min-height: 130px;
        }

        p {
          margin-bottom: 10px;
        }
      }
      
      .tipText {
        margin: 15px 0;

        a {
          color: var(--dark_color);
        }

        ul {
          padding-left: 33px;
        }
      }
    }

    @include breakpoint($tabletWidth) {
      padding: 28px 32px 32px 32px;

    }

    @include breakpoint($mobileWidth) {
      padding: 18px 22px 22px 22px;
      width: 100%;
      max-width: none;

      box-shadow: none;

      .authSideInner {
        height: 90%;
      }
    }
  }

  .stepsSidebar {
    width: var(--sidebar_width);
    height: inherit;
    padding-top: calc(var(--header_height) + 20px);
    padding-left: var(--large);
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;

    border-right: 1px solid var(--border_color);

    .stepDetail {
      padding: 10px 0;
      margin-bottom: 5px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .stepDetailHead {
        display: flex;
        align-items: center;

        svg {
          path {
            stroke: var(--dark_color);
          }
        }
      }

      svg {
        margin-right: 10px;
      }

      h3 {
        font-weight: 400;
        font-size: var(--x-small);
      }

      .hourglassSVG {
        path {
          stroke: var(--dark_color);
        }
      }

      .checkedSVG {
        width: 12px;
        height: auto;

        path {
          stroke: var(--green);
        }
      }

      .xSVG {
        path {
          stroke: var(--red);
        }
      }

      @include breakpoint($tabletWidth) {
        h3 {
          display: none;
        }
      }
    }
  }

  .background {
    width: 100%;
    height: calc(100vh + 150px);

    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-column-gap: 50px;
    grid-row-gap: 70px;
    justify-items: center;

    position: absolute;
    top: -150px;
    z-index: -1;

    overflow: hidden;
  }
}