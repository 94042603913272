@import "../../../styles/vars";
@import "../../../styles/mixins";

.logo {
  display: flex;
  align-items: center;

  cursor: pointer;

  svg:first-of-type {
    margin-right: 5px;
  }

  svg {
    path, rect {
      fill: var(--dark_color);
    }
  }

  &.small {
    svg:first-of-type {
      margin-right: 0;
    }

    svg:last-of-type {
      width: 100px;
    }
  }

  @include breakpoint($tabletWidth) {
    svg:first-of-type {
      width: 21px;
      height: 27px;
    }

    svg:last-of-type {
      display: none;
    }
  }
}