.customRadio {
  display: block;

  input {
    position: absolute;
    cursor: pointer;
    opacity: 0;
    z-index: -1;
  }

  label {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 17px;
    height: 17px;

    position: relative;
    cursor: pointer;
  }

  label:before {
    content: "";
    width: 17px;
    height: 17px;
    display: inline-block;
    position: relative;
    vertical-align: middle;
    cursor: pointer;
    -webkit-appearance: none;
    background-color:transparent;
    border: 2px solid var(--dark_color);
    border-radius: 50%;
    transition: all 0.3s ease-out;
  }

  label:after {
    content: "";
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 7px;
    height: 7px;

    transform: translate(-50%, -50%) scale(0);
    border-radius: 50%;
    background: var(--dark_color);
    transition: all .2s ease-in-out;

  }

  &::after {
    transition: all 0.3s ease-out;
  }

  input:checked {
    & + label:after {
      transform: translate(-50%, -50%) scale(1);
    }
  }
}
