.paymentDetails {
  margin-bottom: var(--large);
  padding-bottom: var(--large);

  overflow: auto;
  border-bottom: 1px solid var(--border_color);

  .paymentDetailsItemsWrapper {
    width: 100%;
    display: flex;
    align-items: center;
    min-width: 600px;
  }

  .paymentDetailsItem {
    padding-right: var(--large);
    margin-right: var(--large);
    width: fit-content;

    border-right: 1px solid var(--border_color);

    span, small {
      color: var(--secondary_text_color);

      font-weight: 400;
      font-size: var(--x-small);
      line-height: 1.1em;
    }

    span {
      display: block;
      margin-bottom: 5px;
    }

    p {
      font-size: var(--x-small);
      word-break: break-word;
    }

    small {
      display: flex;
      align-items: center;

      font-size: var(--x-small);
      line-height: 1em;

      img {
        width: 10px;
        height: 10px;

        margin-left: 5px;
      }
    }

    .checkoutLinkWrapper {
      display: flex;
      align-items: center;

      a {
        color: var(--dark_color);
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }
      }

      .checkoutLink {
        width: 100%;

        white-space: nowrap;

        text-overflow: ellipsis;
        overflow: hidden;
      }


      svg {
        min-width: 12px;
        margin-left: 15px;

        path {
          stroke: var(--dark_color);
        }
      }
    }

    &:last-of-type {
      border-right: 0;
      margin-right: 0;
      padding-right: 0;
    }
  }
}