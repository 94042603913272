@import "../../styles/vars";
@import "../../styles/mixins";

.customersPage {
  .pageHeader {
    margin-bottom: var(--large);

    .h1 {
      margin-bottom: 5px;

      font-size: var(--large);
    }

    @include breakpoint($mobileWidth) {
      flex-direction: column;
      align-items: center;

      text-align: center;

      button {
        margin-top: 10px;
      }
    }
  }

  .h1 {
    font-weight: 500;
    font-size: var(--x-large);
    line-height: 1em;
  }


}
