.authForm {
  height: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;

  h1 {
    margin-bottom: 5px;
    font-size: var(--xx-large);
    line-height: 1em;
  }

  .description {
    font-size: var(--x-small);
    margin-bottom: 15px;

    color: var(--secondary_text_color);
  }

  .buttonInner {
    display: flex;
    align-items: center;

    svg {
      margin-right: 15px;
    }
  }

  .or {
    margin: 15px 0;
    text-align: center;
  }

  .fieldWrapper {
    margin-bottom: 15px;

    input,
    textarea {
      width: 100%;
    }

    textarea {
      min-height: 130px;
    }

    p {
      margin-bottom: 10px;
    }
  }

  .googleIcon {
    path {
      fill: var(--dark_color);
    }
  }

  .tipText {
    margin: 15px 0;

    a {
      color: var(--dark_color);
    }

    ul {
      padding-left: 33px;
    }
  }
}
