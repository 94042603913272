.datePicker {
  position: relative;

  .front {
    padding: 10px 15px;
    display: flex;
    align-items: center;

    border-radius: var(--border_radius);
    border: 1px solid var(--border_color);
    cursor: pointer;

    .calendarSvg {
      margin-right: 11px;
      width: 14px;
      height: 16px;
    }

    svg {
      path {
        fill: var(--dark_color);
      }
    }
  }

  .interface {
    position: absolute;
    top: 50px;
    right: 0;
    z-index: 1000;
    display: none;
    opacity: 0;

    transition: all .2s ease-in-out;

  }

  .dateRange {
    font-family: inherit;

    background: var(--light_color);
    box-shadow: 2px 2px 40px var(--shadow_color);
    border-radius: var(--border_radius);

    select {
      color: var(--dark_color );
      background: var(--light_color);
    }
  }

  .dateRangeDayNumber {
    span {
      color: var(--dark_color);

      &:after {
        background: var(--primary_color);
      }
    }
  }

  .dateRangeDayPassive {
    color: var(--secondary_text_color);
  }

  .dateRangeNextPrevButton {
    background: var(--primary_color_transparent);

    &:hover {
      background: var(--gray_transparent);
    }
  }

  .dateRangeNextButton i {
    border-color: transparent transparent transparent var(--primary_color);
  }

  .dateRangePrevButton i {
    border-color: transparent var(--primary_color) transparent transparent;

  }

  .dateRangeWeekDay {
    color: var(--secondary_text_color);
  }

  .dateRangeDisplayWrapper {
    opacity: 1;
    background: var(--light_gray);
    border-radius: var(--border_radius) var(--border_radius) 0 0;
  }

  .dateRangeDisplayItem {
    background: var(--light_color);

    input {
      color: var(--dark_color );
    }
  }

  &.visible .interface{
    opacity: 1;
  }

  &.active .interface {
    display: block;
  }
}