.note {
  padding: 10px 15px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  border-radius: var(--border_radius);
  background: var(--light_gray);

  h3 {
    font-weight: 400;
    font-size: var(--x-small);
    line-height: 1.2em;
  }

  p {
    font-weight: 400;
    font-size: var(--small);
    line-height: 1em;

    color: var(--secondary_text_color);
  }

  .actionMenuWrapper {
    position: relative;
  }

  &.secondStyle {
    padding: var(--box_padding);

    background: var(--light_gray);
    border-radius: var(--border_radius);
  }
}