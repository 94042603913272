@import "../../../styles/vars";
@import "../../../styles/mixins";

.dateRangePicker {
  position: relative;

  .front {
    margin: 0 10px;
    padding: 12px 20px;
    display: flex;
    align-items: center;

    cursor: pointer;
    transition: all .2s linear;
    border-radius: var(--border_radius);

    &:hover {
      background: var(--light_gray);
    }
    
    .calendarSvg {
      margin-right: 11px;
      width: 14px;
      height: 16px;
    }

    .arrowSvg {
      margin: 0 15px;
    }

    p {
      font-size: var(--x-small);
      line-height: 1em;

      color: var(--dark_color);
    }

    svg {
      path {
        fill: var(--dark_color);
      }
    }
  }

  .interface {
    position: absolute;
    top: 50px;
    right: 0;
    z-index: 1000;
    display: none;
    opacity: 0;

    transition: all .2s ease-in-out;

  }

  .dateRange {
    font-family: inherit;

    background: var(--light_color);
    box-shadow: 2px 2px 40px var(--shadow_color);
    border-radius: var(--border_radius);

    select {
      color: var(--dark_color );
      background: var(--light_color);
    }
  }

  .dateRangeDayNumber {
    span {
      color: var(--dark_color);

      &:after {
        background: var(--primary_color);
      }
    }
  }

  .dateRangeDayPassive {
    color: var(--secondary_text_color);
  }

  .dateRangeNextPrevButton {
    background: var(--primary_color_transparent);

    &:hover {
      background: var(--gray_transparent);
    }
  }

  .dateRangeNextButton i {
    border-color: transparent transparent transparent var(--primary_color);
  }

  .dateRangePrevButton i {
    border-color: transparent var(--primary_color) transparent transparent;

  }

  .dateRangeWeekDay {
    color: var(--secondary_text_color);
  }

  .dateRangeDisplayWrapper {
    opacity: 1;
    background: var(--light_gray);
    border-radius: var(--border_radius) var(--border_radius) 0 0;
  }

  .dateRangeDisplayItem {
    background: var(--light_color);

    input {
      color: var(--dark_color );
    }
  }


  &.visible .interface{
    opacity: 1;
  }

  &.active .interface {
    display: block;
  }

  @include breakpoint($mobileWidth) {
    width: 100%;

    .front {
      justify-content: center;
    }
  }
}