.customerViewHeader {
  margin-bottom: var(--x-large);
  display: flex;
  justify-content: space-between;
  align-items: center;

  .topCustomerTitle {
    display: flex;
    align-items: center;

    h2 {
      margin-left: 12px;
      font-weight: 700;
      font-size: var(--medium);
    }
  }

  .customerNumber {
    font-size: var(--x-small);

    color: var(--secondary_text_color);
  }
}

.customerView {

  .customerMainInfo {
    margin-bottom: var(--x-large);
    display: flex;
    justify-content: space-between;

    button {
      font-family: inherit;

      font-size: var(--x-small);

      background: transparent;
      border: none;
      color: var(--primary_color);
      cursor: pointer;
    }
  }

  .customer {
    display: flex;
    align-items: center;

    .customerAva {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 35px;
      height: 35px;
      margin-right: 10px;

      text-transform: uppercase;

      background: var(--light_gray);
      border-radius: 50%;
    }

    h4 {
      margin-bottom: 0;
      line-height: 1em;
      font-size: var(--medium);
    }

    .span {
      display: block;
      font-size: var(--x-small);
      color: var(--secondary_text_color);
    }
  }

  .noteWrapper {
    margin-bottom: var(--x-large);
  }


  .section {
    margin-bottom: var(--x-large);

    .h2 {
      margin-bottom: var(--x-medium);

      font-weight: 400;
      font-size: var(--medium);
      line-height: 1em;
    }

    .tableHeader {
      width: calc(100% + var(--box_padding) * 2);
      padding: 5px var(--box_padding);
      display: flex;
      justify-content: space-between;
      position: relative;
      left: calc(0% - var(--box_padding));

      border-bottom: .5px solid var(--border_color);
      border-top: .5px solid var(--border_color);

      h3 {
        width: 100%;
        font-size: var(--x-small);
        color: var(--secondary_text_color);
        font-weight: 400;
      }
    }

    .tableBody {
      display: flex;
      justify-content: space-between;

      .tableBodyItem {
        padding: 10px 0;
        width: 100%;
        p {
          font-size: var(--medium);
        }
      }
    }
  }

  .paginationWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 10px 0;
    width: 100%;
  }
}