.errorBoundary {
  max-width: 600px;
  margin: 50px auto;

  padding: var(--box_padding);
  box-shadow: 4px 4px 40px var(--shadow_color);
  border-radius: var(--border_radius);
  text-align: center;

  h1 {
    font-size: var(--large);
    margin-bottom: 10px;
  }
}