$ticketsHeaderHeight: 70px;

.ticketsPage {
  min-height: calc(100vh - (var(--header_height) + 20px));
  display: grid;
  grid-template-columns: 30% 70%;

  border: 1px solid var(--border_color);
  border-radius: var(--border_radius);

  .sidebar {
    border-right: 1px solid var(--border_color);
  }

  .sidebarHeader {
    padding: var(--medium);

    display: flex;
    flex-direction: column;
    justify-content: center;
    height: $ticketsHeaderHeight;

    border-bottom: 1px solid var(--border_color);

    .h1 {
      font-size: var(--large);
    }
  }

  .sidebarBody {
    height: 100%;
    padding: var(--medium);

    .filters {
      margin-bottom: var(--x-medium);
      display: flex;
      justify-content: space-between;

      select {
        font-family: inherit;
        font-size: var(--small);

        border: 0;
        outline: 0;
        background: transparent;
      }
    }

    .tickets {
      height: 80vh;

      overflow: auto;
    }
  }

  .openTicket {

    .openTicketHeader {
      padding: var(--medium);
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: $ticketsHeaderHeight;

      border-bottom: 1px solid var(--border_color);
    }

    .openTicketSecondHeader {
      padding: 11px var(--medium);
      display: flex;
      justify-content: space-between;
      align-items: center;

      border-bottom: 1px solid var(--border_color);

      .addressWrapper {
        display: flex;

        p {
          margin-right: 20px;
        }
      }

      button {
        border: 0;
        outline: 0;
        background: transparent;

        font-family: inherit;
        font-size: var(--x-small);

        cursor: pointer;
        text-decoration: underline;
      }
    }

    .openTicketBody {
      height: 80vh;
      display: flex;
      flex-direction: column;
      padding: var(--medium);

      .messagesWrapper {
        margin-bottom: 20px;
        height: 90%;

        overflow: auto;
      }

      .messageFormWrapper {
        margin-top: auto;
      }
    }
  }
}