@import "../../styles/vars";
@import "../../styles/mixins";

.paymentTimelineSection {
  margin-bottom: var(--large);

  header {
    margin-bottom: 16px;
  }

  .paymentDetailTimelines {
    display: flex;

    @include breakpoint($mobileWidth) {
      flex-direction: column;
    }
  }

  .timelineWrapper {
    margin-right: 30px;

    &:last-of-type {
      margin-right: 0;
    }

    @include breakpoint($mobileWidth) {
      margin-right: 0;
    }
  }
}