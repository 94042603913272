@import "../../styles/vars";
@import "../../styles/mixins";

.paymentMainDetails {
  padding-bottom: var(--large);
  margin: var(--large) 0;
  display: flex;
  justify-content: space-between;
  align-items: center;

  border-bottom: 1px solid var(--border_color);

  .actionMenu {
    position: relative;
  }

  .paymentMainDetailsRightSide {
    display: flex;
    align-items: center;

    @include breakpoint($sMobileWidth) {
      flex-direction: column;
      align-items: flex-start;
    }

    .priceDetails {
      margin-right: 15px;

      @include breakpoint($sMobileWidth) {
        margin-bottom: 10px;
      }

      p {
        margin-bottom: 5px;
        display: flex;
        align-items: center;

        font-weight: 400;
        font-size: var(--x-small);
        line-height: 1em;

        color: var(--secondary_text_color);

        svg {
          width: 15px;
          height: 10px;
          margin-right: 5px;

          path {
            stroke: var(--dark_color);
          }
        }
      }

      h2 {
        font-weight: 400;
        font-size: var(--large);
        line-height: 1.2em;

      }

      span {
        font-weight: 400;
        font-size: var(--x-small);
        line-height: 1em;

        color: var(--secondary_text_color);

        img {
          width: 10px;
          height: 10px;
          margin-left: 5px;
        }
      }
    }

  }

  .paymentMainDetailsLeftSide {
    display: flex;
    align-items: center;

    button {
      margin-right: var(--large);
    }

    .paymentIdDetail {
      margin-right: 20px;

      span {
        display: block;
        width: 100%;

        text-align: right;
        font-weight: 400;
        font-size: var(--small);
        line-height: 1em;

        color: var(--secondary_text_color);
      }

      p {
        font-weight: 400;
        font-size: var(--medium);
        line-height: 1em;

        @include breakpoint($mobileWidth) {
          margin-left: 20px;
          max-width: 140px;
        }
      }
    }
  }
}
