.statusBadge {
  width: fit-content;
  display: flex;
  align-items: center;
  height: 24px;
  padding: 0 7.5px;

  border-radius: var(--border_radius);

  svg {
    width: 11px;
    height: 11px;
    margin-left: 10px;
  }

  span {
    margin-bottom: 0!important;
    color: inherit!important;
  }

  &.green {
    background-color: var(--green_transparent);
    color: var(--green);

    svg {
      path {
        stroke-width: 1.5px;
        stroke: var(--green)
      }
    }
  }
  &.orange {
    background-color: var(--orange_transparent);
    color: var(--orange);

    svg {
      path {
        stroke-width: 1.5px;
        stroke: var(--orange)
      }
    }
  }
  &.gray {
    background: var(--gray_transparent);
    color: var(--gray);

    svg {
      path {
        fill: var(--gray)
      }
    }
  }
  &.red {
    background: var(--red_transparent);
    color: var(--red);

    svg {
      path {
        fill: var(--red)
      }
    }
  }
  &.blue {
    background: var(--blue_transparent);
    color: var(--blue);

    svg {
      path {
        fill: var(--blue)
      }
    }
  }
}