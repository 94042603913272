.pagination {
  width: 100%;
  display: flex;
  justify-content: space-between;

  .pageSizeController {
    select {
      background: none;
      outline: none;
      border: none;

      font-size: var(--x-small);
      font-family: inherit;
      color: var(--dark_color);
    }
  }

  .paginateContainer {
    display: flex;
    align-items: center;
    list-style: none;

    font-size: var(--x-small);
  }

  .paginatePrevNext {
    padding: 1px 9px;

    border-radius: 5px;
    cursor: pointer;

    &:hover {
      background: var(--light_gray);
    }
  }

  .paginatePage {
    margin: 0 5px;

    border-radius: 5px;
    border: 1px solid var(--border_color);
    
    a {
      padding: 1px 7px;

      cursor: pointer;

      &:hover,
      &.active {
        background: var(--light_gray);
        border-radius: 5px;
      }
    }

  }

  .paginateActive {
    background: var(--light_gray);
  }
}