.messageForm {
  width: 100%;
  position: relative;

  textarea {
    padding: var(--small);
    padding-right: 50px;
    width: 100%;
    min-height: 90px;

    font-family: inherit;
    font-size: var(--medium);

    resize: none;
    background: var(--light_gray);
    border: 0;
    border-radius: var(--border_radius);
  }

  button {
    position: absolute;
    right: 40px;
    top: 50%;

    transform: translateY(-50%);

    border: 0;
    outline: 0;
    background: transparent;
    cursor: pointer;
  }
}