@import "../../styles/vars";
@import "../../styles/mixins";

.authHeader {
  position: relative;
  z-index: 2;
  width: 100%;
  padding: 0 var(--large);
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: var(--header_height);

  border-bottom: 1px solid var(--border_color);

  .headerLeftSide {
    width: 50%;
    display: flex;
    align-items: center;
  }

  .headerRightSide {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    p {
      font-size: var(--x-small);
    }

    button {
      margin: 0 20px;
    }

  }
}