.statItem {
  display: flex;
  flex-direction: column;
  width: 100%;

  .statisticsItemInfo {
    flex: 1;
    display: flex;
    justify-content: space-between;

    h3 {
      font-weight: 400;
      font-size: var(--x-small);
      line-height: 1.2em;

      color: var(--secondary_text_color);

      svg {
        margin-left: 5px;
      }
    }

    .numbers {
      display: block;

      font-weight: 600;
      font-size: var(--large);
      line-height: 1.2em;
    }

    .changeLabel {
      padding: 2px 9px;

      font-weight: 400;
      font-size: var(--small);
      line-height: 1.2em;

      background-color: var(--primary_color_transparent);
      border-radius: var(--border_radius);
      color: var(--primary_color);

      &.up {

      }

      &.down {
        background-color: var(--red_transparent);
        color: var(--red);
      }
    }
  }

  .statisticsItemGraph {
    width: 100%;

    .dates {
      margin-top: -10px;
      display: flex;
      justify-content: space-between;

      font-weight: 400;
      font-size: var(--x-small);
      line-height: 1.2em;

      color: var(--secondary_text_color);
    }
  }

  &.withoutGraph {
   .statisticsItemInfo {
     display: block;
   }
  }

  &.horizontal {
    display: flex;
    flex-direction: row;
    align-items: center;

    .statisticsItemInfo {
      min-width: fit-content;
      flex: 1;
    }

    .statisticsItemGraph {
      margin-left: 10px;
    }
  }
}