@import "../../styles/vars";
@import "../../styles/mixins";

.table {
  position: relative;
  width: 100%;

  margin-top: var(--medium);
  overflow-x: auto;


  .tableHeader {
    padding: 5px 10px;
    display: flex;
    justify-content: space-between;

    border-top: 1px solid var(--border_color);
    border-bottom: 1px solid var(--border_color);
    min-width: 600px;

    h3 {
      margin-right: 10px;
      width: 15%;
      font-weight: 400;

      font-size: var(--x-small);
      line-height: 1em;
      text-transform: uppercase;

      color: var(--secondary_text_color);

      &.large {
        width: 34%;
      }

      &.medium {
        width: 20%;
      }

      &.settings {
        width: 2%;
      }

      &.full {
        flex: 1;
      }
    }
  }

  .actionMenu {
    position: relative;
  }

  .mb10 {
    margin-bottom: 10px;
  }

  .tableBodyWrapper {
    padding: 3px 0;

    border-bottom: 1px solid var(--border_color);
  }

  .tableBody {
    padding: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-width: 600px;

    cursor: pointer;
    border-radius: var(--border_radius);
    border-bottom: 1px solid var(--border_color);
    text-decoration: none;
    color: var(--dark_color);

    &:last-of-type {
      border-bottom: none;
    }

    &:hover {
      background: var(--light_gray);
    }

    .nameWrapper {
      display: flex;
      align-items: center;

      img,
      svg {
        margin-right: 10px;

        path {
          stroke: var(--dark_color);
        }
      }

      .name {
        height: fit-content;

        h3 {
          margin-bottom: 0;
        }

        span {
          display: block;
        }
      }

      .memberLogo {
        margin-right: 10px;
        min-width: 35px;
        height: 35px;
        display: flex;
        justify-content: center;
        align-items: center;

        background: var(--dark_color);
        border-radius: 50%;

        svg,
        img {
          max-width: 20px;
          height: auto;

          rect {
            fill: var(--light_color);
          }
        }
      }
    }

    .tableBodyItem {
      margin-right: 10px;
      width: 15%;

      .buttons {
        display: flex;
        align-items: center;

        svg:first-of-type {
          margin-right: 15px;
        }
      }

      h4 {
        font-weight: 500;
        font-size: var(--x-small);
        line-height: 1.2em;
      }

      h3 {
        font-size: var(--x-small);
        line-height: 1em;
      }

      span {
        font-size: var(--x-small);
        line-height: 1em;
        font-style: normal;
        font-weight: 400;

        color: var(--secondary_text_color);
      }

      p {
        display: flex;
        align-items: center;
        word-break: break-word;

        font-weight: 400;
        font-size: var(--x-small);
        line-height: 1.2em;

        svg {
          margin-left: 10px;
        }

        &.small {
          font-size: var(--x-small);
        }
      }

      input {
        margin-bottom: 5px;
        width: 99%;
      }

      &.large {
        width: 34%;
      }

      &.medium {
        width: 20%;
      }

      &.settings {
        width: 2%;
      }

      &.full {
        flex: 1;
      }
    }
  }
}