@import "../../../styles/vars";
@import "../../../styles/mixins";

.settingsBillingPage {

  .cryptocurrencies {
    margin-bottom: var(--large);
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: var(--large);

    @include breakpoint($tabletWidth) {
      grid-template-columns: repeat(2, 1fr);
    }
    @include breakpoint($mobileWidth) {
      grid-template-columns: repeat(1, 1fr);
    }
  }

  .section {
    .sectionHeader {
      margin-bottom: var(--large);

      .sectionTitle {
        margin-bottom: 10px;

        display: flex;
        align-items: center;

        h1 {
          margin-bottom: 0;
          margin-right: 15px;
          font-weight: 500;
          font-size: var(--x-medium);
        }
      }

      p {
        font-weight: 400;
        color: var(--secondary_text_color);

        a {
          color: var(--dark_color);
        }
      }
    }
  }

  .recommendedBadge {
    padding: 6px 8px;

    font-size: var(--x-small);
    text-transform: uppercase;

    background: var(--gray_transparent);
    border-radius: var(--border_radius);
  }

  .fieldBox {
    position: relative;
    margin-bottom: var(--large);

    padding: var(--box_padding);

    box-shadow: 2px 2px 40px var(--shadow_color);
    border-radius: var(--border_radius);

    &.danger {
      background: var(--red_transparent);
      color: var(--red);
    }

    header {
      display: flex;
      align-items: center;
      justify-content: space-between;

      h2 {
        margin-bottom: 2px;
        font-weight: 500;
        font-size: var(--x-medium);
        line-height: 1.2em;
      }

      p {
        font-weight: 400;
        font-size: var(--medium);
        line-height: 1.2em;

        color: var(--secondary_text_color);
      }

      .buttons {
        display: flex;
        align-items: center;

        button:first-of-type {
          margin-right: 20px;
        }
      }

      @include breakpoint($mobileWidth) {
        margin-bottom: 10px;
        flex-direction: column;
        align-items: flex-start;

        p {
          margin-bottom: 10px;
        }
      }
    }

    .inputWrapper {
      width: 45%;

      input {
        width: 100%;
      }
    }

    .fieldBoxBody {
      display: flex;
      align-items: center;
      width: 100%;

      input {
        width: 100%;
      }

      button {
        margin-left: auto;
        margin-top: var(--x-medium);
      }
    }

    .thresholdFields {
      margin-top: 20px;
      width: 100%;
      display: flex;
      justify-content: space-between;

      .thresholdField {
        width: 49%;

        h3 {
          font-weight: 500;
          margin-bottom: 5px;
        }

        p {
          margin-bottom: 10px;
          color: var(--dark_color);


        }
      }

      @include breakpoint($mobileWidth) {
        flex-direction: column;

        .thresholdField {
          width: 100%;
          margin-bottom: 10px;

          &:last-of-type {
            margin-bottom: 0;
          }
        }
      }
    }

    .buttonWrapper {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      opacity: 0;

      max-height: 0;
      visibility: hidden;
      transform: translateY(-100%);
      transition: all .2s linear;

      &.active {
        transform: translateY(0);
        margin-top: var(--large);
        max-height: 50px;
        opacity: 1;
        visibility: visible;
      }

      button {
        margin-left: 0;
        margin-top: 0;
      }
    }

    footer {
      margin-top: 10px;
    }

    .supportsCrypto {
      display: flex;
      align-items: center;

      p {
        margin: 0;
        margin-right: 10px;

        color: var(--dark_color);
      }

      .cryptos {
        display: flex;

        img:nth-child(2) {
          position: relative;
          left: -15%;
        }

        img:nth-child(3) {
          position: relative;
          left: -27%;
        }

        img:nth-child(4) {
          position: relative;
          left: -39%;
        }
      }
    }
  }
}