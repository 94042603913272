.searchInput {
  width: 100%;
  display: flex;
  align-items: center;

  .iconWrapper {
    margin-right: 15px;
    min-width: 35px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;

    background: var(--light_gray);
    border-radius: var(--border_radius);

    svg {
      path {
        stroke: var(--dark_color);
      }
    }
  }

  input {
    padding: 0;
    width: 100%;

    font-size: var(--x-small);

    border: none;
    background: transparent;
  }

  &.secondary {
    .iconWrapper {
      min-width: 0;
      margin-right: 10px;
      height: auto;
      width: fit-content;
      background: none;

      svg {
        path {
          stroke: var(--secondary_text_color);
        }
      }
    }
  }
}