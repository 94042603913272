.paymentMethodModal {
  max-width: 438px;
  margin: 0 auto;
  padding: var(--box_padding);

  background: var(--light_color);
  border-radius: var(--border_radius);

  .warning {
    padding: 16px;
    margin-bottom: 1em;

    color: var(--red);
    background: var(--red_transparent);
    border-radius: var(--border_radius_m);

    h3 {
      margin-bottom: 5px;
      font-size: var(--medium);
    }
  }

  .header {
    margin-bottom: var(--medium);
    display: flex;
    align-items: center;

    svg {
      margin-right: 9px;
    }

    .h1 {
      margin-bottom: 0;
      font-weight: 400;
      font-size: var(--medium);
    }
  }

  .logo {
    margin-bottom: var(--medium);
  }

  .description {
    margin-bottom: var(--medium);
    color: var(--secondary_text_color);

    a {
      color: inherit;
    }
  }

  .tabs {
    margin-bottom: var(--small);
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    background: var(--light_gray);
    border-radius: var(--border_radius);

    .tab {
      display: flex;
      align-items: center;
      justify-content: center;

      padding: 5px;
      width: 50%;

      font-size: var(--x-small);

      border-radius: var(--border_radius);
      transition: all .2s linear;
      cursor: pointer;

      img {
        margin-left: 10px;
        width: 13px;
        height: 13px;
      }

      &.active,
      &:hover {
        background: var(--light_color);
      }
    }
  }

  .fieldWrapper {
    margin-bottom: var(--medium);
    width: 100%;

    h2 {
      display: flex;
      align-items: center;
      margin-bottom: 10px;

      text-transform: uppercase;
      font-weight: 400;
      font-size: var(--x-small);

      img {
        width: 18px;
        height: 18px;

        margin-right: 10px;
      }
    }

    header {
      margin-bottom: 10px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;

      h2 {
        margin-bottom: 0;
      }

      .p {
        margin-top: 5px;
        color: var(--secondary_text_color);
      }
    }

    input {
      width: 100%;
    }
  }

  .radios {
    margin-bottom: var(--small);
    display: flex;
  }

  .radioWrapper {
    margin-right: var(--small);
    display: flex;
    align-items: center;

    cursor: pointer;

    span {
      margin-left: 10px;
      font-size: var(--xx-small);
      text-transform: uppercase;
    }
  }

  .sliderWrapper {
    .sliderDots {
      margin-top: 5px;
      display: flex;
      justify-content: space-between;

      font-size: var(--xx-small);
    }
  }

  .footer {
    button {
      margin-bottom: var(--medium);
      width: 100%;
    }
  }
}