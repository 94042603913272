.customerTicket {
  margin-bottom: 10px;
  padding: var(--x-small);

  font-size: var(--x-small);

  border-radius: var(--border_radius);
  transition: all .2s linear;
  cursor: pointer;

  header {
    width: 100%;
    display: flex;
    justify-content: space-between;

    h2 {
      font-size: var(--x-small);
    }
  }

  .email {
    margin-bottom: 5px;
  }

  .message {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 100%;
  }

  &:hover,
  &.active {
    background: var(--light_gray);
  }
}